export const FeedbackList = [
  {
    name: "Jeremy Reynaud",
    position: "CEO - ShareItAgain",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "I’ve successfully outsourced several projects to Taras and his team, over the past few years. They have a strong understanding of current development best practices and always provide good communication. All these factors keep us hiring them for more work.",
    link: "https://clutch.co/profile/tetalab-0#review-2137576"
  },
  {
    name: "Aabesh De",
    position: "CEO - Florasence",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab has been instrumental to Flora’s app growth over the last year. Taras from TetaLab was a pleasure to work with, and as a result of their design, iOS , and Android development services, we were able to grow our revenue 3x in just 6 months while working with them. Their team was able to integrate with our technical team immediately, and were easy to communicate and work with. I can't recommend them enough!",
    link: "https://clutch.co/profile/tetalab-0#review-2149820",
  },
  {
    name: "Oleh Smolkin",
    position: "CEO - Startup Allez",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_pyqgh9.svg",
    comment: "TetaLab team was very helpful with in-time delivery of MVP. They have worked collaboratively and efficiently provide high-quality code in terms of uncertainty.",
    link: "https://clutch.co/profile/tetalab-0#review-2129195"
  },
  {
    name: "Halvard Maalqvist",
    position: "CEO - Ackurat Learn",
    avatar: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535382/Main/Mask_group_2_mwkqsr.svg",
    comment: "TetaLab had provided exceptional approach to every stage of the developing process."
  },
];

export const CareersList = [
  {
    id: "lead_generation_specialist",
    title: "Lead Generation Specialist",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/Junior_Sales_Manager_onhsiy.svg",
    requiredSkills: [
      "Fluent/Advanced English",
      "Strong communications skills",
      "Perseverance, attentiveness to details, regularity",
      "Self-motivated and focused on growth and success within a company"
    ],
    plus: [
      "Knowledge and understanding of the market and the specifics of sales in IT",
      "Experience with lead generation and international sales of software services (UpWork, Linkedin)"
    ],
    offer: [
      "Well paid Full-time job",
      "Career growth in the company",
      "We teach how to work and provide all the required info"
    ],
    responsibilities: [
      "Search & prepare proposals for potential customers on Upwork and LinkedIn",
      "Communicate with potential customers to promote products and company services",
      "Improve and optimize the process of communication with clients"
    ]
  }
]

export const CareersList_ua = [
  {
    id: "lead_generation_specialist",
    title: "Lead Generation Specialist",
    img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535381/Main/Junior_Sales_Manager_onhsiy.svg",
    requiredSkills: [
      "Вільне володіння англійською мовою",
      "Сильні комунікативні навички",
      "Посидючість, уважність до деталей, регулярність",
      "Само мотивованість і зосередженість на зростанні та успіху в компанії"
    ],
    plus: [
      "Знання та розуміння ринку та специфіки продажів в IT",
      "Досвід генерації потенційних клієнтів та міжнародних продажів програмного забезпечення (UpWork, Linkedin)"
    ],
    offer: [
      "Добре оплачувана повна зайнятість",
      "Кар'єрний ріст в компанії",
      "Навчаємо працювати та надаємо всю необхідну інформацію"
    ],
    responsibilities: [
      "Пошук і підготовка пропозицій для потенційних клієнтів на Upwork і LinkedIn",
      "Спілкування з потенційними клієнтами для просування продуктів і послуг компанії",
      "Покращити та оптимізувати процес спілкування з клієнтами"
    ]
  },
];

export const MainTitles_en = [
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "find",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "solution",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "create",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "design",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
  [
    {
      value: "Let`s",
      type: "black",
    },
    {
      value: "develop",
      type: "underlined",
    },
    {
      value: "the",
      type: "black",
    },
    {
      value: "best",
      type: "black",
    },
    {
      value: "software",
      type: "color",
    },
    {
      value: "together",
      type: "black",
    },
  ],
];

export const MainTitles_ua = [
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "знайдемо",
      type: "underlined",
    },
    {
      value: "найкраще",
      type: "black",
    },
    {
      value: "рішення",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "створимо",
      type: "underlined",
    },
    {
      value: "найкращий",
      type: "black",
    },
    {
      value: "дизайн",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
  [
    {
      value: "Давайте",
      type: "black",
    },
    {
      value: "розробимо",
      type: "underlined",
    },
    {
      value: "найкращий",
      type: "black",
    },
    {
      value: "софт",
      type: "color",
    },
    {
      value: "разом",
      type: "black",
    },
  ],
];
