import { PLATFORMS, TECHNOLOGIES, TEAM, TOOLS, TERM } from "./Specifications";

const Projects_en = [
  {
    id: "ackurat_learn",
    type: ["Education", "UI/UX", "Android"],
    title: "Ackurat learn",
    subtitle: "Flashcards to remember new words",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310409/tetalab%20site/AckuratCover_lcw4xr.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177173/tetalab%20site/learn_zkojwe.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310755/tetalab%20site/Screen_1_ppqooy.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310948/tetalab%20site/Phones_mockup_-_Shopping_list_r3nb1q.jpg",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310888/tetalab%20site/AckuratScreen_gbm8oe.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "A dynamic language app for travelers, offering engaging study with Flashcards, Match, and Listening exercises.",
    description: "Whether you're planning an exciting trip abroad or looking to enrich your downtime, a language app can help you build your vocabulary, develop proper grammar, and eventually become fluent through lessons that are easy to digest - all from the comfort of your smartphone. \n" +
      " \n" +
      "Ackurat Learn is an app that will help you to study languages. Choose the best way for yourself - Match to find correspondence between words and translation, or Listen to practice your pronunciation.",
    android: ["https://play.google.com/store/apps/details?id=com.app.ackuratlearnandroid"],
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/144603915/Ackurat-Learn"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.TEXT_TO_SPEECH,
          TECHNOLOGIES.IN_APP_PURCHASE,
          TECHNOLOGIES.ROOM
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'For successful language learning, users must remain motivated over an extended period. Therefore, the app should provide engaging and meaningful lessons and the option to choose from various learning methods.',
      'Users have different learning preferences, so the app should offer a variety of methods, such as using flashcards to memorize new words, the "Match" game to find correspondences between words and translations, or the "Listening" function for pronunciation practice.',
      'Considering the busy schedules of many users, the app should provide a quick and efficient way to learn a language. This could involve short and intensive lessons that can easily fit into daily routines.',
    ],
    resumes: [
      'We prioritized user motivation by designing Ackurat Learn to offer engaging and meaningful language lessons, ensuring a positive learning experience for travelers and individuals seeking enriching downtime.',
      'Ackurat Learn provides a variety of user-friendly learning methods. Users can choose from Flashcards for vocabulary, Match for word correspondences, or Listening exercises for pronunciation, tailoring their language learning journey.',
      'With busy lifestyles in mind, Ackurat Learn delivers easy-to-digest and structured language lessons, allowing users to acquire new language skills efficiently.',
      'To combat boredom, frustration, and demotivation, Ackurat Learn incorporates gamification elements, creating a positive, motivating learning environment.',
      'Harnessing smartphone convenience, Ackurat Learn allows users to learn languages anytime, anywhere, providing a portable solution for language enthusiasts on the go.',
      'Beyond basic vocabulary and grammar, Ackurat Learn facilitates comprehensive language study. It offers a holistic approach to vocabulary building, proper grammar, and fluency enhancement within a single application.'
    ],
    result: 'Ackurat Learn is our dynamic language-learning solution, addressing engaging and efficient study needs. The app offers flexible learning through Flashcards, Match, and Listening exercises. Designed to combat boredom and demotivation, Ackurat Learn ensures easy, enjoyable, and adaptable language learning to fit busy lifestyles.'
  },
  {
    id: "shopping_list",
    type: ["Ordering Systems", "Utils", "UI/UX", "iOS", "Flutter"],
    title: "Shopping list",
    subtitle: "Streamline your shopping experience",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1708963243/tetalab%20site/Cover_2_o2xykn.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176554/tetalab%20site/Slide_3_ovtotw.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709112202/tetalab%20site/Screen_fskhju.png",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535433/Projects/Phones_mockup_-_Shopping_list_1_ldmzs1.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709300810/tetalab%20site/Screens_tjqujy.jpg",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Mobile application focused on the user’s easy and basic needs when creating lists, adding new items, and sharing lists with friends and family.",
    description: "Before going to the store, a grocery list is prepared either mentally or written down. Sometimes shopping may be stressful, so the best solution in this situation is to let the shopping list app take out all the work for you.\n" +
      " \n" +
      "Our Shopping List is a user-centric mobile application designed with simplicity in mind for creating lists, incorporating new items as well as sharing these listings among friends and family members.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/127122541/Shopping-Lists"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.IOS,
          PLATFORMS.ANDROID
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.IN_APP_PURCHASE,
          TECHNOLOGIES.FIREBASE
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.FOUR_WEEKS
        ]
      },
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Design a visually appealing and user-friendly interface.',
      'Boost user adoption by making the app an essential daily tool.',
      'Tackle the challenge of creating an efficient list management system.',
      'Ensuring seamless integration across multiple platforms and devices.'
    ],
    resumes: [
      'Developed a mobile application focused on enhancing user convenience by facilitating list creation, item addition, and effortless sharing with friends and family.',
      'Created a welcoming atmosphere through vibrant colors and intuitive design, ensuring easy navigation without the need for tutorials.',
      'Enabled users to effortlessly generate new shopping lists, populate them with items, and seamlessly share them with their friends.',
      'Implemented simple categorization and the ability to organize items into smaller groups for enhanced user ease.',
      'Introduced an instant sharing feature for lists, allowing users to select recipients directly from their contacts or invite them via email.',
      'Guaranteed smooth compatibility across different platforms and devices.'
    ],
    result: 'Shopping List is an innovative mobile application designed to effortlessly meet users\' fundamental needs. They can quickly make lists of needed items and keep track of what’s already been bought and what still needs to be purchased. The app has a user-friendly interface and simplifies adding new items to lists and lets you share them with friends and family.'
  },
  {
    id: "hodor_keyboard",
    type: ["Entertainment", "UI/UX", "Android"],
    title: "Hodor Keyboard",
    subtitle: "Exchange secret messages",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709548824/hodor_cover_qhgpti.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549021/Screen_kidauu.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549202/What_we_did_1_q3wa6a.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177267/tetalab%20site/hodor_z9rewn.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549308/Phones_mockup_-_Hodor_Keyboard_illjax.jpg",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "With the Hodor Keyboard app, you can effortlessly encode and decode your text and exchange secret messages that no other could read.",
    description: "With the Hodor Keyboard app, you can easily encode and decode your text and exchange secret messages that no other could read.\n" +
      " \n" +
      "By turning on the Hodor cipher, you can write whatever you want, and our keyboard will automatically encode your message, using the word «Hodor» or any other word you choose. You can use «Decoder» to read encoded messages by inserting the encoded message.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123378515/Hodor-Keyboard-UIUX-Design"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.KEYBOARD,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'The main task was to ensure seamless encoding and decoding of text. This involved creating functional capabilities that allow users to effortlessly encrypt and decrypt messages using a chosen keyword.',
      'The primary goal was to create a program for exchanging secret messages, unread by outsiders, ensuring confidentiality and protection of conversation content.',
      'Providing users the ability to customize encryption parameters, such as language choice, color themes, and keywords for message encoding. The main screen of the program became the central hub for these settings.',
      'Developing an application with a user-friendly interface, where users could customize the keyboard, choose languages, color themes, and keywords for message encoding and decoding on both the main screen and the «Decryptor» screen.',
    ],
    resumes: [
      'We developed the Hodor Keyboard app to enable users to encode and decode text, facilitating the exchange of secret messages unreadable by others.',
      'Users can write freely by activating the Hodor cipher. The keyboard automatically encodes the message using the chosen keyword, such as «Hodor» or any other word of their preference.',
      'The «Decoder» function allows users to easily read encoded messages.',
      'Hodor Keyboard is designed for situations where users want to hide their conversation content from outsiders. The mobile app, equipped with a custom keyboard, automatically encodes all text and messages using a user-selected keyword for enhanced privacy.',
      'The Hodor Keyboard application boasts a comfortable and understandable user flow. Key components include the Main screen for customizing the keyboard with language choices, color themes, and keywords for message encoding, along with the significant «Decryptor» screen facilitating message decoding and transcript management.',
    ],
    result: 'The Hodor Keyboard app provides effortless encoding and decoding for private messaging. Activating the Hodor cipher allows automatic message encoding with a user-chosen keyword, ensuring personalized privacy. The «Decoder» function enables easy reading of encoded messages, promoting a user-friendly experience. With a custom keyboard and comfortable user flow, the app automates text and message encoding, delivering a secure and personalized communication platform.'
  },
  {
    id: "LiveAir",
    type: ["IoT", "UI/UX", "Android", "iOS", "FrontEnd", "Backend"],
    title: "LiveAir",
    subtitle: "Simplifying air quality monitoring",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Cover_3_wgmslb.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Screen_2_mcqt3r.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176958/tetalab%20site/air_yrspjt.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360847/Main/Group_1151504_sh1ubi.svg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Phones_mockup_-_LiveAir_pqk32x.jpg",
    reading: "9",
    date: "верес 24, 2020",
    previewDescription: "With this app, you can manage pollution detecting devices and get not only overall pollution but also the ratio of each pollutant.",
    description: "In recent years, the environment is rapidly changing, and air quality is a big part of it. Air pollution is a serious health risk that needs to be controlled.\n" +
      " \n" +
      "We developed “LiveAir” - a mobile application, web platform, and server. \n" +
      " \n" +
      "This system helps us manage pollution-detecting devices and provide high-resolution air quality monitoring by visualizing all vital information as detailed and understandable charts.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123373415/LiveAir"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
          PLATFORMS.WEB
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.C,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.WIFI,
          TECHNOLOGIES.NODE_MCU,
          TECHNOLOGIES.FIREBASE,
          TECHNOLOGIES.REACT,
          TECHNOLOGIES.NODEJS
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.SIX_WEEKS,
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.QA
    ],
    clientDescription: "Our partner, driven by a commitment to tackle environmental challenges, reached out to us for help in developing an app. \n"  +
      " \n" +
      "With a focus on public health and combating air pollution, they chose \"LiveAir\" as the solution. Together, we're on a mission to make environmental care more accessible through user-friendly technology.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356138/Main/Group_1000003093_ccedf8.svg",
    challenges: [
      'Developing strategies to cope with the rapid changes in the environment, recognizing the crucial role of air quality in this transformation.',
      'Undertaking the challenge of mitigating air pollution as a significant health hazard, emphasizing the need for effective control measures.',
      'Integrating a mobile application, web platform, and server to manage pollution-detecting devices and provide detailed, high-resolution air quality monitoring.',
      'Designing the system to visualize vital information through detailed and understandable charts.',
    ],
    resumes: [
      'Engineered a holistic solution — LiveAir — with a mobile app, web platform, and server to address evolving environmental challenges.',
      'Integrated advanced technologies into pollution-detecting devices, ensuring seamless management via the LiveAir mobile application and web platform.',
      'Achieved comprehensive air quality monitoring through LiveAir\'s high-resolution visualizations, presenting vital information in easily understandable charts.',
      'Implemented a crucial feature in LiveAir, delivering real-time notifications to users, enabling swift responses to potential air quality hazards.',
      'Equipped Live Air with the capability to not only provide an overall pollution overview but also deliver a detailed analysis of each pollutant\'s ratio for a nuanced understanding.',
    ],
    result: 'LiveAir, our innovative solution, addresses the urgent global challenge of monitoring air pollution through its mobile application, web platform, and server. By effectively managing pollution-detecting devices, LiveAir provides users with high-resolution air quality monitoring displayed in detailed charts. In today\'s world, where air pollution poses significant health risks worldwide, LiveAir ensures real-time notifications, empowering users to monitor air pollution.'
  },
  {
    id: "fun_recycle",
    type: ["Education", "UI/UX", "Android", "iOS", "FrontEnd", "Flutter"],
    title: "Fun Recycle",
    subtitle: "Find recycling boring? Let`s do it with fun",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_cover_eytek8.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551340/fun_recycle_Screen_hidais.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_resume_nfmpbu.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_background_mpom1d.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177008/tetalab%20site/recycle_yuzkur.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Mobile application aimed to help you understand how waste recycling affects the environment and how everyone can contribute to environmental wellness.",
    description: "Environmental safety is one of the essential goals in our lives. It is our responsibility to preserve nature and prevent pollution.\n" +
      " \n" +
      "Fun Recycle is a mobile application that makes waste recycling tracking easy and informative. With this app, users can record the amount of waste they recycle and select specific periods to receive detailed reports. Additionally, they can learn about the environmental impact of recycling and earn rewards for their active participation in the recycling process.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123419183/Fun-Recycle"
      }
    ],
    clientDescription: "Our client, seeking to enhance environmental awareness and promote conscious waste management, targets two primary segments: environmentally conscious individuals seeking advanced tracking and motivation, and newcomers, including adults and children, aspiring to adopt sustainable practices. \n"  +
      " \n" +
      "FUN Recycle addresses this dual audience with its user-friendly platform, fostering an eco-conscious community through engaging features and educational tools.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356137/Main/image_1_hsdqxk.svg",
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
          PLATFORMS.WEB,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.REACT,
          TECHNOLOGIES.ROOM
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Our primary goal was to contribute to environmental conservation and pollution prevention by developing an application focused on promoting nature safety.',
      'A key task was to design Fun Recycle with a user-friendly interface, ensuring accessibility for individuals interested in monitoring their waste recycling activities.',
      'The team aimed to raise awareness about recycling through the app, offering users comprehensive information on the environmental benefits of waste recycling.',
      'An essential objective was to educate users about the environmental impact of recycled trash. The application serves as a resource to inform individuals about the positive effects of their recycling efforts.',
    ],
    resumes: [
      'We conceptualized and developed Fun Recycle - a mobile application dedicated to simplifying waste recycling tracking and increasing awareness of its environmental impact.',
      'To enhance user experience, we designed a user-friendly interface within Fun Recycle, enabling individuals to effortlessly monitor their recycling efforts.',
      'Fun Recycle allows users to mark and customize their recycling activities, selecting specific periods for a comprehensive overview of their environmental contributions.',
      'Through the app, we integrated educational features, enabling users to learn about the environmental impact of recycling and fostering a deeper understanding of sustainable consumption practices.',
      'Incentivizing active participation, we introduced a reward system to motivate users who actively engage in recycling practices.',
      'Our focus was on empowering users, providing a tool for understanding personal contributions to environmental wellness and encouraging active participation in collective efforts for a healthier planet.',
    ],
    result: 'Fun Recycle is a mobile application designed for environmental conservation, providing intuitive waste tracking and information on the environmental impact of recycling. Users can easily track and customize their actions. With an educational interface and reward system, responsible waste recycling is encouraged.'
  },
  {
    id: "trailer_Watchdog",
    type: ["IoT", "UI/UX", "Android", "iOS"],
    isDark: true,
    isCaseStudy: true,
    title: "Trailer Watchdog",
    subtitle: "Security and safety for your trailer in one click",
    thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715363000/trailer_watchdog_cover_krmgvh.jpg",
    logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715603121/trailer_watchdog_1_r2lgsv.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/Frame_otjuny.svg",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535422/Projects/Phones_mockup_-_Trailer_Watchdog_j2yzvs.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180624/tetalab%20site/security_zsm3rs.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Revolutionizes trailer management with a user-friendly app that seamlessly monitors axle temperatures and delivers vital notifications.",
    description: "The Trailer Watchdog app is a connectivity and display solution designed to report the Bluetooth device’s data conveniently. Monitor trailers axle temperatures, recieve notifications and store important information with the app. \n" +
      " \n" +
      "Development was carried out in the Android SDK environment working with the modern programming language Kotlin. There were used Bluetooth technologies. Implemented reception and display of data f rom Bluetooth devices, tracking critical temperatures and notification about it, as well as savind an array af data f rom one session in the phone’s memory.",
    clientDescription: "At the end of 2021, we received a request to assist in Android app development and IoT device integration for a security system for a USA company. The main idea of their product is to track the metrics of trailers. If deviations from the norm are detected, the owner is notified of potential problems with the trailer.",
    clientDescriptionItems: [
      'Monitoring key metrics such as axle temperatures, tire pressure, and others.',
      'Real-time visualization of key metrics',
      'Receiving notifications about problems.',
      'Storing information about ride sessions.',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715603121/trailer_watchdog_2_rqn45r.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/112477221/TrailerWatchdog"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.PCB,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.SIX_WEEKS,
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.QA
    ],
    challenges: [
      'Сreate an application that will make trailer maintenance more comfortable.',
      'Collect and analyze data that can be stored during the ride session.',
      'Design of a Mobile app to control smart devices.',
      'Easy-to-understand data visualizations.',
      'Manage the trailer system only using a smartphone.',
    ],
    resumeDescription: "We developed a comprehensive connectivity and display solution focused on efficiently reporting Bluetooth device data. Our primary aim was to monitor trailer axle temperatures and provide users with timely notifications. The development process took place within the Android SDK environment, leveraging the modern Kotlin programming language. We seamlessly integrated Bluetooth technologies to facilitate the reception and display of data from connected devices. Key functionalities include tracking critical temperatures and delivering notifications when necessary. Furthermore, we designed the app to intelligently store an array of data from each session in the phone's memory, ensuring easy access for users to monitor temperature metrics seamlessly.",
    result: 'Trailer Watchdog offers enhanced security and safety for your trailer through our innovative connectivity solution.',
    resultItems: [
      'Monitor axle temperatures and receive real-time notifications, ensuring you stay informed about your trailer\'s condition, anytime and anywhere.',
      'The app boasts an intuitive interface, making it easy to manage and access essential information, providing a hassle-free experience for users.',
      'Establishing a robust connection with trailer devices using advanced Bluetooth technologies ensures reliable data reception and display.',
      'Trailer Watchdog intelligently stores data from each monitoring session in the phone\'s memory, allowing users to track and analyze temperature metrics over time.',
      'The app dynamically displays trailer configuration data, providing users with a visual representation of critical temperature metrics for enhanced monitoring and decision-making.',
    ],
    systemIncludes: [
      'Robust Bluetooth connectivity, allowing seamless communication between the Trailer Watchdog app and the Bluetooth devices attached to the trailer.',
      'Advanced sensors for monitoring and displaying real-time axle temperatures, providing users with crucial insights into their trailer\'s condition.',
      'Integration of a sophisticated notification system, delivering alerts to users when critical temperatures are detected, ensuring prompt response to potential issues with the trailer.',
      'Efficient data storage mechanisms, enable the system to store and organize information from each user session, facilitating easy retrieval and analysis of historical temperature metrics.',
      'An intuitive and user-friendly interface, allowing users to manage their trailer system effortlessly through their smartphones, thereby enhancing overall user experience and accessibility.',
    ],
  },
  {
    id: "flora",
    type: ["IoT", "UI/UX", "Android", "AI", "iOS", "Education"],
    isDark: true,
    isFlora: true,
    isCaseStudy: true,
    title: "Plant Care App",
    subtitle: "Your ultimate plant care companion",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717061355/tetalab%20site/pexels-soumil-kumar-735911_2_ehvv3k.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062125/tetalab%20site/Group_1000003092_fg5ro7.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/All_steps_illustration_rjtpbc.svg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062322/tetalab%20site/Phones_mockup_-_Flora_qajlf8.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180433/tetalab%20site/flora_jbrse0.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Our plant care app revolutionizes plant nurturing, offering unparalleled convenience and support to users.",
    description: "Our project focuses on developing a comprehensive mobile application that revolutionizes the way individuals engage with plant care. We aimed to create a multifaceted platform that caters to the diverse needs of plant enthusiasts at every level of expertise.\n" +
      " \n" +
      "From plant identification and personalized care advice to community engagement and gamified experiences, this project encompasses a wide range of functionalities designed to empower users. By leveraging the latest advancements in IoT and mobile technology, we aim to set a new standard for plant care applications.",
    clientDescription: "Over a year ago, we were approached to contribute to the development of an iOS app, integrate Bluetooth devices, and finalize the Android app build-out for a leading U.S. startup specializing in plant care solutions. They recognized the growing demand for user-friendly and technologically advanced tools to simplify and enhance the plant care experience.",
    clientDescriptionItems: [
      'Custom plant care devices',
      'Mobile apps for both Android and iOS platforms',
      'A community of plant care users',
      'Additional services to help with plant care',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062179/tetalab%20site/Group_1000003093_y3sanr.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/112477221/TrailerWatchdog"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.FIREBASE,
          TECHNOLOGIES.IN_APP_PURCHASE,
          TECHNOLOGIES.BLUETOOTH
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.PHOTOSHOP,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.NINE_WEEKS
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Elevate the design and development process to optimize efficiency',
      'Enhance documentation and specifications to ensure clarity and accuracy throughout the project lifecycle',
      'Facilitate seamless communication among design, product, and development teams across different time zones',
      'Design and develop an Android app',
    ],
    resumeDescription: `We embarked on a series of strategic initiatives and implemented various solutions to effectively tackle the multifaceted challenges encountered. Our team diligently addressed different project aspects, ensuring successful execution and the attainment of set objectives. Leveraging our knowledge and expertise, we developed strategies to optimize processes. Our overarching goal was to ensure efficient resource utilization and deliver the best possible outcomes for our clients.`,
    resumes: [
      'Provide a dedicated team in the same time zone for faster communication and development.',
      'Provide additional flows and rules for better team organization.',
      'Analyze the initial iOS app and provide a plan for Android app development. This stage improved our general understanding of existing projects, and we made initial documentation.',
      'Use a modern software architecture for mobile apps. This resulted in a decrease in the number of issues and a speed-up of new development.',
    ],
    result: 'Our plant care application provides unparalleled assistance and convenience, tailored to the unique needs and preferences of plant enthusiasts.',
    resultItems: [
      'The iOS app reached more than 110k users this year.',
      'More than $65K has been raised through Kickstarter and startup competition thanks to changes to the app.',
      'Top 25% in monthly subscription retention across all iOS apps.',
      'The revenue has continued to grow by 45% MoM(!) since the application launch.',
    ],
    systemIncludes: [
      'Custom plant care devices, which encompass specialized hardware solutions tailored to optimize plant health',
      'Mobile apps for both Android and iOS platforms',
      'A community of plant care users, where they can connect with fellow plant enthusiasts',
      'Gamifiсation that immerses users in the entertaining aspect of plant care. They can earn rewards as they care for their plants, making every bloom a memorable occasion.',
    ],
  },
  {
    id: "lviv_city_guide",
    type: ["Tourism", "UI/UX", "Android", "iOS"],
    title: "Lviv City Guide",
    subtitle: "City guides - your custom experience",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552359/lviv_city_guide_Cover_fkkunx.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552359/lviv_city_guide_Screen_rebjx0.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552360/lviv_city_guide_Screens_hzyw0o.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552360/lviv_city_guide_background_u1pobw.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177050/tetalab%20site/guide_t4x8sq.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "A mobile app designed to help users efficiently plan their trips by providing useful information and convenient routes for a comfortable travel experience.\n",
    description: "Many tourist cities are unable to offer guides in multiple languages. They require guides equipped with a list of points of interest, brief descriptions, and locations.\n" +
      " \n" +
      "We have developed a framework application that can be adapted for different cities. Users can upload information about points of interest, tours, and other amenities.\n" +
      " \n" +
      "As a result, cities can provide mobile guides featuring information about tours, attractions, public services, and offer maps with navigation.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/92597467/City-Guide-Case-Study"
      },
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535423/Projects/youtube-brands_oq9mew.svg",
        link: "https://www.dropbox.com/s/omblun0rsi1ese3/CityGuide_live_video.mp4?dl=0"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.OSM,
          TECHNOLOGIES.ROOM,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.DESKTOP_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'To overcome the language barrier in tourist cities, develop a multilingual guide for visitors with diverse language backgrounds.',
      'Create a universal app adapted to meet the specific requirements of different cities and their unique landmarks.',
      'Enable users to actively contribute by uploading information about points of interest, tours, and other valuable details, fostering the creation of a collaborative platform.',
      'Develop the City Guide program to assist users in efficiently planning trips, offering useful information, and providing convenient routes for a comfortable travel experience.',
    ],
    resumes: [
      'Developed a multilingual app to overcome language barriers in tourist destinations.',
      'Created guides featuring a list of points of interest with short descriptions and precise locations.',
      'Designed a flexible application framework that can be easily adapted to meet the unique requirements of different cities.',
      'Implemented a user-friendly platform allowing users to actively contribute by uploading information about points of interest, tours, and other valuable details.',
      'Enabled cities to provide mobile guides with information about tours, attractions, public services, and navigation maps.',
      'Incorporated features to assist users in managing time and planning routes efficiently, ensuring a streamlined and enjoyable travel experience.',
      'Enabled users to share their moments and experiences with others.',
    ],
    result: 'City Guide revolutionizes travel with multilingual guides, comprehensive information, and a user-friendly app. Users actively contribute, empowering cities to offer mobile guides with details on tours, attractions, public services, and navigation. This program transforms trip planning, providing a convenient tool for efficient exploration, time management, route planning, and shared experiences. City Guide redefines travel, making it multilingual and personalized.'
  },
  {
    id: "museum_guide",
    type: ["Tourism", "UI/UX", "Android", "iOS", "Backend"],
    isDark: true,
    title: "Museum Guide",
    subtitle: "Collection of guides for various museums",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Cover_me5poj.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Screen_ab2ofi.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Screens_dhemwf.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_background_fa30ht.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177122/tetalab%20site/museum_xrviia.png",
    previewDescription: "Offering multilingual audio guides, the application empowers museums to provide comprehensive exhibit information.",
    description: "Museums have visitors from different countries. Tourists would like to take audio guides in their languages. \n" +
      " \n" +
      "We designed and developed an audio guide application that can be modified for different museums. \n" +
      " \n" +
      "As a result, museums can offer guides providing information about the exhibitions along with audio tracks.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/94423251/Museum-Guide"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.MEDIA_PLAYER,
          TECHNOLOGIES.FIREBASE
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Provide support for a wide range of languages so that visitors can choose their preferred language for the audio guide.',
      'Museums may have different requirements for the audio guide content, so the application should be flexible to allow quick changes and modifications.',
      'Develop an application that can work efficiently for both large and small museums, providing an optimal experience for visitors regardless of the institution\'s size.',
      'Develop the Museum Guide app to provide comprehensive guides with detailed exhibition information.',
    ],
    resumes: [
      'Developed an audio guide application to address the multilingual needs of museums, enabling tourists to explore exhibits in their preferred languages.',
      'Designed a flexible app that allows seamless modifications to cater to the specific requirements of different museums, ensuring versatility in application use.',
      'Implemented features to empower museums to provide comprehensive guides with detailed exhibition information, enhancing the overall visitor experience.',
      'Created the Museum Guide, a diverse collection of guides tailored for various museums, offering both short and extended versions to accommodate the unique characteristics of different cultural institutions.',
      'Developed two distinct versions of the application, addressing the needs of both small and large museums. The short and extended versions ensure optimal functionality across a spectrum of cultural institutions.',
      'The Museum Guide application, as a result of our efforts, enhances visitor engagement by providing customizable audio guides that transcend language barriers, making cultural experiences more accessible and enjoyable for a diverse audience.',
    ],
    result: 'Enhance global museum experiences with our versatile Museum Guide app, which offers multilingual audio guides. The application empowers museums to provide comprehensive exhibit information and audio tracks. Available in short and extended versions, it caters to the distinct needs of diverse cultural institutions, making cultural exploration more accessible for visitors worldwide.'
  },
  {
    id: "game-bacterial_war",
    type: ["Entertainment", "UI/UX", "Android"],
    isDark: true,
    isFlora: true,
    title: "Game - Bacterial War",
    subtitle: "Mobile strategy game",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180193/tetalab%20site/Bg_xbp2fv.png",
    logo_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535438/Projects/Group_1279_cseyvh.svg",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535431/Projects/Phones_mockup_-_Bacterial_War_sbyxg7.png",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535431/Projects/Phones_mockup_-_Bacterial_War_sbyxg7.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177358/tetalab%20site/bacterial_jqeah7.png",
    previewDescription: "Most of us",
    description: "Most of us were in conditions when they were waiting for meetings, appointments, and others. In this case, users play mobile games to not get bored. \n" +
      " \n" +
      "We developed a mobile strategy game in a genre of strategy/colony building.\n" +
      " \n" +
      "In this game, the user is able to control bacterial colonies and fight for space and resources. During the game, the user meets new enemies and neutral colonies. The game includes 40 levels with different difficulties. ",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/92617529/Bacterial-War-game-Case-Study"
      },
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535423/Projects/youtube-brands_oq9mew.svg",
        link: "https://www.youtube.com/watch?v=WCdyWg_opp4"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.MEDIA_PLAYER,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.DESKTOP_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Provide support for a wide range of languages so that visitors can choose their preferred language for the audio guide.',
      'Museums may have different requirements for the audio guide content, so the application should be flexible to allow quick changes and modifications.',
      'Develop an application that can work efficiently for both large and small museums, providing an optimal experience for visitors regardless of the institution\'s size.',
      'Develop the Museum Guide app to provide comprehensive guides with detailed exhibition information.',
    ],
    resumes: [
      'Developed an audio guide application to address the multilingual needs of museums, enabling tourists to explore exhibits in their preferred languages.',
      'Designed a flexible app that allows seamless modifications to cater to the specific requirements of different museums, ensuring versatility in application use.',
      'Implemented features to empower museums to provide comprehensive guides with detailed exhibition information, enhancing the overall visitor experience.',
      'Created the Museum Guide, a diverse collection of guides tailored for various museums, offering both short and extended versions to accommodate the unique characteristics of different cultural institutions.',
      'Developed two distinct versions of the application, addressing the needs of both small and large museums. The short and extended versions ensure optimal functionality across a spectrum of cultural institutions.',
      'The Museum Guide application, as a result of our efforts, enhances visitor engagement by providing customizable audio guides that transcend language barriers, making cultural experiences more accessible and enjoyable for a diverse audience.',
    ],
    result: 'Enhance global museum experiences with our versatile Museum Guide app, which offers multilingual audio guides. The application empowers museums to provide comprehensive exhibit information and audio tracks. Available in short and extended versions, it caters to the distinct needs of diverse cultural institutions, making cultural exploration more accessible for visitors worldwide.'
  },
  {
    id: "greenHouse_control_system",
    type: ["IoT", "UI/UX", "Android", "iOS", "Backend"],
    title: "GreenHouse control system",
    subtitle: "Automated greenhouse system prototype",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709299797/tetalab%20site/AckuratCover_gbhnbx.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709299896/tetalab%20site/AckuratScreen_glf1pu.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709301690/tetalab%20site/Screens_1_ikeiup.jpg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709300169/tetalab%20site/Phones_mockup_-_GreenHouse_control_system_uifiaz.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176823/tetalab%20site/green_penfyz.png",
    previewDescription: "Through automated processes, the system optimizes agricultural productivity, offering a streamlined solution for efficient and effective plant management.",
    description: "Farmers use a lot of greenhouses. Usually, automated greenhouses can’t provide the possibility to remotely maintain and monitor system conditions.\n" +
      " \n" +
      "We developed an automated greenhouse system prototype, with a mobile application and server. \n" +
      " \n" +
      "The user can track system status and manually control system behavior. Hardware reads sensors data and sends it over Wi-Fi to the remote Firebase server.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/96341613/Greenhouse-Controller"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.C,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.NODE_MCU,
          TECHNOLOGIES.FIREBASE,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    clientDescription: "Our client sought an innovative IoT solution for greenhouse management, including automatic control. They envisioned an intuitive Dashboard listing plants with customizable settings (temperature, light preferences), and seamless addition of new plants to the system was crucial. \n"  +
      " \n" +
      "Passionate about greenhouse cultivation, our client sought a user-friendly app for real-time monitoring and precise control over environmental parameters for each plant in their greenhouse.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356137/Main/Group_152_axj8yh.svg",
    challenges: [
      'Developing a reliable system for remotely monitoring greenhouse conditions.',
      'Integrating manual control features into the mobile application while ensuring seamless communication with the hardware and server.',
      'Designing a user-friendly interface, considering the system\'s need to cater to a diverse range of users, from individual farmers to professional agronomists.',
      'Establishing a real-time response system for user control over greenhouse conditions, especially when quick adjustments are needed.',
    ],
    resumes: [
      'Created an automated greenhouse system prototype to address the limitations of traditional automated greenhouses.',
      'Designed and implemented a robust system that combines hardware, a mobile application, and a server.',
      'Developed an intuitive and user-friendly mobile application interface for easy navigation and control.',
      'Integrated manual control features, allowing users to have direct control over the greenhouse system\'s behavior.',
      'Ensured seamless communication between the mobile application, hardware, and server.',
      'Implemented a mechanism for efficient sensor data reading by the hardware.',
      'Provided users with real-time monitoring capabilities.',
      'Empowered users to make immediate adjustments to the system based on real-time data.',
    ],
    result: 'The Green House Control System revolutionizes plant care for farmers and agronomists with its user-friendly interface and comprehensive plant information. Real-time tracking and manual control features empower users to make informed decisions, ultimately saving time and enhancing plant care confidence. Through automated processes, the system optimizes agricultural productivity, offering a streamlined solution for efficient and effective plant management.'
  },
  {
    id: "android_POS",
    type: ["Ordering Systems", "IoT", "UI/UX", "Android", "FrontEnd", "Backend"],
    isDark: true,
    isCaseStudy: true,
    hasSystemArchitectureImg: true,
    title: "Android POS",
    subtitle: "How to make an Automated POS system for fruits and veggies shops",
    thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715023561/Android_POS_cover_qhsooz.jpg",
    logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715030500/Android_POS_About_Project_w7hlbb.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/Scheme_jcjbdq.svg",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535432/Projects/Phones_mockup_-_GreenHouse_control_system_1_ejahwn.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180482/tetalab%20site/android_ls3sda.png",
    previewDescription: "Simplify your shopping with our app—quick weighing, inventory tracking, streamlined payments, and faster checkouts. Step into the future of cost-effective retail.",
    description: "Shops are constantly seeking new ways to automate processes and reduce costs, often relying on POS systems.\n" +
      " \n" +
      "We participated in developing an automated POS system for a startup in the EU. The system is based on Android devices with custom hardware and a backend server.\n" +
      " \n" +
      "By implementing this system, administrators can reduce purchase times and minimize queues within the shop. Additionally, the system can be expanded to operate in a self-service mode.",
    clientDescription: "Our company had a request to assist in developing hardware and software for the Belgian startup in small shop automation. The main idea of their project is to simplify the shopping experience through self-management and payment. The system can reduce costs and simplify shop management.",
    clientDescriptionItems: [
      'Simplify the work of stores that use scales to weigh goods.',
      'Keep track of the remaining goods.',
      'Equipment management system.',
      'Speed up the work of cashiers.',
      'Access to statistics and user purchases.',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715066870/About_Client_IMG_Android_POS_davaqz.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/94421677/Android-POS"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.PYTHON,
          TECHNOLOGIES.C,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.PCB,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.NINE_WEEKS,
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Design affordable hardware that can be placed in stores.',
      'Design and development of reliable Tablet apps.',
      'Convenient admin panel for shop management.',
      'Assemble and configure several devices for system testing.',
      'Simple integration with existing systems.',
      'Development of a user-friendly interface and accessible instructions for installing the system that will be understandable for people with a non-technical education.',
    ],
    resumes: [
      'Used a modern software architecture for mobile apps that\'s easy to improve and support.',
      'Designed, soldered, and wired custom PCBs to connect tablets, card readers, external weighing scales, and receipt printers.',
      'Designed and 3D printed enclosures for devices.',
      'Designed web admin panel and server to link all elements together.',
      'Provided a dedicated design and development team with a whole skill set to complete projects from idea to final assembly.',
    ],
    result: 'Automated POS - is a custom system that provides a simplified and convenient way to weigh and pay for fruits and veggies. The project has a lot of benefits:',
    resultItems: [
      'Reduced the work of the cashier or replaced them;',
      'Customers can make purchases without interaction with a cashier;',
      'Prepared a project for investor evaluation;',
      'The waiting time in a queue in a small store with 1 cash register was on average 4 minutes with an average store load, and after the introduction of the Android POS system - 2.5 minutes (reduced by 38%).',
    ],
    systemIncludes: [
      'Android application to control weighing scales and user baskets.',
      'Admin Android application to control baskets, pay and print receipts.',
      'Web Admin dashboard to manage devices, stocks, and products.',
      'Design and assembled custom devices based on Android tablets, custom PCBs, card readers, weighing scales, and thermal printers.',
    ]
  },
  {
    id: "english_words",
    type: ["Education", "UI/UX", "Android", "iOS"],
    title: "English Words",
    subtitle: "Create your own dictionary",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_Cover_i7qm0l.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_screen_209_sg0em0.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_Screens_ufjppz.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556429/english_words_Phones_mockup_-_English_Words_jrpkli.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177232/tetalab%20site/english_z6x1rf.png",
    previewDescription: "The app facilitates language learning by prioritizing the top 1000 words, providing effortless vocabulary management.",
    description: "Do you know that by learning the 1000 most frequently used words, you can understand 70% of phrases?\n" +
      " \n" +
      "Learning a new language, there is always a need to write down unknown words. Some people use a notebook for this, while others prefer a sheet of paper. Our application will help you solve this problem and create your own dictionary.",
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.TEXT_TO_SPEECH,
          TECHNOLOGIES.IN_APP_PURCHASE,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.SKETCH,
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Our primary goal was to assist language learners in memorizing the most frequently used words, facilitating a 70% comprehension of common phrases.',
      'We aimed to streamline the recording and organization of new words, providing users with a convenient platform to create and manage their dictionary.',
      'With a focus on English language learners, our challenge was to design an intuitive app that encourages memorization, expands vocabulary, and ensures correct pronunciation.',
      'To maintain user motivation, the app needed features for tracking progress, allowing learners to monitor their advancements, review words, and assess pronunciation accuracy.',
    ],
    resumes: [
      'English Words focuses on learning the top 1000 words, helping users understand 70% of common phrases for efficient language acquisition.',
      'The app simplifies word organization, replacing traditional methods with a user-friendly interface for creating and managing personal dictionaries.',
      'Tailored for English language learners, English Words supports memorization, vocabulary expansion, and correct pronunciation for diverse proficiency levels.',
      'In addition to vocabulary, the app facilitates progress tracking, pronunciation practice, and word repetition for a comprehensive language learning experience.',
      'Designed for daily use, English Words seamlessly integrates into users\' routines, ensuring constant access to personal dictionaries.',
      'By enabling users to create dictionaries, English Words offers a personalized language learning journey at their own pace.',
    ],
    result: 'English Words transforms language learning by focusing on the top 1000 words, which account for 70% of phrase comprehension. The app simplifies vocabulary management with its user-friendly interface, catering to English language learners for memorization, pronunciation, and progress tracking. Designed for daily use, it seamlessly integrates into routines, providing constant access to personal dictionaries and fostering continuous improvement in English proficiency.'
  },
  {
    id: "mind_map",
    type: ["Utils", "UI/UX", "Android"],
    title: "Mind Map",
    subtitle: "Everything you need - in one place",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_Cover_b3turk.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_logoimg_nprsmj.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_resume_mdt7if.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/Phones_mockup_-_Mind_Map_jk3msc.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177307/tetalab%20site/mind_qrllga.png",
    previewDescription: "This all-in-one solution streamlines organizational needs, providing an efficient and seamless experience for managing thoughts and goals.",
    description: "Users like to think and dream, but they may not have where to store their thoughts. One of the best options is to use a mobile device that is always with the user. \n" +
      " \n" +
      "We designed and developed a mobile application for saving a list of goals and topics, tracking progress, scheduling tasks, and more.\n" +
      " \n" +
      "The application provides a platform for listing goals, tracking priorities, and monitoring results.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/95191207/MindMap-Task-organizer"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.ROOM,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.ILLUSTRATOR,
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Creating a mobile application, that will provide users with a convenient and accessible platform for storing and organizing their thoughts, goals, and schedules.',
      'Ensuring users have seamless access to their information anytime, anywhere. Creating a system that effectively handles large volumes of data.',
      'Developing a comprehensive tool for managing and monitoring personal and professional goals.',
      'Integrating features for progress tracking, planning, and organizing essential information. This provides users with a comprehensive solution for managing their thoughts and goals in one centralized place.',
    ],
    resumes: [
      'We developed a mobile application that serves as a centralized platform for users to save, organize, and track their goals, topics, and progress. Users can efficiently manage their thoughts, dreams, and aspirations all in one place.',
      'The application provides a dynamic list of goals, enabling users to track priorities and measure results. This feature enhances users\' organizational abilities and helps them stay focused on their objectives.',
      'The Mind Map application ensures users have instant access to their tasks, thoughts, schedules, and vital information anytime, anywhere.',
      'We prioritized creating a user-friendly interface, allowing for easy navigation and utilization of the Mind Map application. The design facilitates a seamless experience for users in managing their thoughts and goals.',
      'Understanding the challenges of dealing with vast amounts of information, we designed the application to efficiently process and organize data. Users can rely on the Mind Map app for quick retrieval of essential information.',
      'The result is an all-encompassing Mind Map application that acts as a comprehensive solution for users\' organizational needs. It consolidates tasks, thoughts, goals, schedules, and important information in a single, easily accessible place.',
    ],
    result: 'The Mind Map application offers a comprehensive platform for users to organize thoughts and goals conveniently. Designed for user ease, it allows tracking progress, scheduling, and managing a variety of goals and topics. In today\'s information-heavy world, the app ensures instant access to tasks, thoughts, schedules, and essential information anytime, anywhere. This all-in-one solution streamlines organizational needs, providing an efficient and seamless experience for managing thoughts and goals.'
  },
  // { //TODO temporary commented Healthcare. Add term and team info
  //   id: "healthcare_platform",
  //   type: ["UI/UX", "Android", "FrontEnd", "Backend", "Healthcare", "Flutter"],
  //   isDark: true,
  //   isCaseStudy: true,
  //   title: "Healthcare Platform",
  //   subtitle: "Streamlining prescription and appointment scheduling",
  //   thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971903/pexels-soumil-kumar-735911_2_eksyrx.jpg",
  //   logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_1000003094_i5vnkg.png",
  //   resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360693/Main/Group_1000003055_seirtf.svg",
  //   bg_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_705_comhlo.jpg",
  //   img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180527/tetalab%20site/healthcare_ohafcq.png",
  //   previewDescription: "Healthcare Platform streamlines prescription management and appointment scheduling, enabling seamless coordination between doctors and patients.",
  //   description: "The main aim was to create an innovative application tailored to the diverse needs of physicians and patients, prioritizing exceptional user-friendliness and unparalleled convenience.\n" +
  //     " \n" +
  //     "This involved modernizing the existing system to optimize administrative tasks, streamline workflows, ensure reliable communication channels, and implement robust data protection measures to safeguard sensitive information.",
  //   clientDescription: "Last year, our company received a request from a client to redesign the mobile app and admin panel for a clinic in Germany. The goal was clear - create an application that seamlessly meets the needs of both doctors and patients, prioritizing ease of use and convenience.",
  //   clientDescriptionItems: [
  //     'Time-saving administrative solutions.',
  //     'Streamlined organizational workflows for doctors.',
  //     'Reliable patient communication and engagement.',
  //     'Detailed patient history tracking.',
  //     'Uncompromised data protection.',
  //   ],
  //   clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_1000003095_bspopj.png",
  //   specifications: [
  //     {
  //       platform: [
  //         PLATFORMS.ANDROID,
  //         PLATFORMS.IOS,
  //       ],
  //     },
  //     {
  //       technologies: [
  //         TECHNOLOGIES.KOTLIN,
  //         TECHNOLOGIES.ANDROID,
  //         TECHNOLOGIES.NODE_MCU,
  //         TECHNOLOGIES.REACT,
  //       ],
  //     },
  //     {
  //       tools: [
  //         TOOLS.FIGMA,
  //         TOOLS.PHOTOSHOP,
  //         TOOLS.ILLUSTRATOR,
  //       ],
  //     },
  //   ],
  //   challenges: [
  //     'Optimize administrative tasks to allocate more time for significant matters.',
  //     'Create a simple and clear design to ensure user-friendly navigation for all age groups.',
  //     'Achieve reliable communication from constant phone calls and emails to clear, encrypted, and secure patient interactions.',
  //     'Ensured secure processing and encryption of patient data.',
  //   ],
  //   resumeDescription: "We simplified administrative processes through the admin panel, saving time for medical staff. The mobile app enabled secure encrypted communication between doctors and patients, eliminating the need for constant phone calls and emails.",
  //   resumes: [
  //     'Designed a straightforward and intuitive user interface to ensure easy navigation for users of all ages.',
  //     'Set up a security system with strong encryption methods and controlled access to protect sensitive data.',
  //     'Enabled real-time updates for patient data and appointment schedules for better accuracy and efficiency.',
  //   ],
  //   result: 'Healthcare Platform - a streamlined solution designed to enhance efficiency in administrative tasks, improve communication with patients, facilitate patient management, and ensure data protection, all while seamlessly integrating with common PVS practice calendars.',
  //   resultItems: [
  //     'Simplified administrative tasks for doctors and practitioners.',
  //     'Enhanced direct communication between doctors and patients.',
  //     'Ensured secure processing and encryption of patient data.',
  //     'Integrated seamlessly with common PVS practice calendars for easy scheduling.',
  //   ],
  //   systemIncludes: [
  //     'Comprehensive mobile apps developed for both Android and iOS platforms.',
  //     'An intuitive and streamlined admin panel for optimizing administrative tasks.',
  //     'Simple and clear design to ensure user-friendly navigation for all age groups.',
  //     'Top-tier data safety measures, including strong encryption, to ensure the highest level of data protection.',
  //   ]
  // },
];

const Projects_ua = [
  {
    id: "ackurat_learn",
    type: ["Education", "UI/UX", "Android"],
    title: "Ackurat learn",
    subtitle: "Картки для запам'ятовування нових слів",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310409/tetalab%20site/AckuratCover_lcw4xr.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177173/tetalab%20site/learn_zkojwe.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310755/tetalab%20site/Screen_1_ppqooy.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310948/tetalab%20site/Phones_mockup_-_Shopping_list_r3nb1q.jpg",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709310888/tetalab%20site/AckuratScreen_gbm8oe.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Динамічний додаток для вивчення мови для мандрівників, який пропонує захопливе навчання за допомогою флеш-карток, гри у відповідність і вправ для вимови.",
    description: "Не залежно, чи ви плануєте захоплюючу поїздку за кордон, або бажаєте збагатити свій вільний час, мобільний додаток з вивчення мови може допомогти вам розширити словниковий запас, вивчити граматику і зрештою оволодіти мовою завдяки урокам, які легко засвоюються - все це за допомогою вашого смартфона. \n" +
      " \n" +
      'Ackurat Learn - це додаток, який допоможе вам вивчати мови. Оберіть найкращий спосіб для себе - "Відповідність", щоб знайти відповідність між словами і перекладом, або "Прослуховування", щоб вдосконалити вашу вимову.',
    android: ["https://play.google.com/store/apps/details?id=com.app.ackuratlearnandroid"],
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/144603915/Ackurat-Learn"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.TEXT_TO_SPEECH,
          TECHNOLOGIES.IN_APP_PURCHASE,
          TECHNOLOGIES.ROOM
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Для успішного вивчення мови користувачі повинні залишатися вмотивованими протягом тривалого періоду часу. Тому додаток повинен надавати захопливі уроки, а також можливість вибору різних методів навчання.',
      `Користувачі мають різні уподобання в навчанні, тому додаток повинен пропонувати різноманітні методи, такі як використання флеш-карток для запам'ятовування нових слів, гру "Відповідність" для знаходження відповідностей між словами і перекладами, або функцію "Прослуховування" для практики вимови.`,
      'Враховуючи зайнятість багатьох користувачів, додаток повинен надавати швидкий та ефективний спосіб вивчення мови. Він може включати в себе короткі та інтенсивні уроки, які легко практикувати щодня.',
    ],
    resumes: [
      'Ми підвищили мотивацію користувачів, розробивши Ackurat Learn для ознайомлення з захопливими уроками з вивчення мов, що забезпечує позитивний досвід навчання для мандрівників та людей, які бажають покращити якість своїх знань.',
      'Ackurat Learn пропонує різноманітні методи навчання, прості в користуванні. Користувачі можуть обирати серед флеш-карток для покращення словникового запасу, гри "Відповідність" для встановлення відповідностей між словами, або вправ для вимови "Прослуховування".',
      'Звертаючи увагу на зайнятість користувачів, Ackurat Learn надає легкі для розуміння та структуровані уроки з вивчення мов, що дозволяє користувачам ефективно здобувати нові мовні навички.',
      'Для боротьби з нудьгою, розчаруванням та втратою мотивації, Ackurat Learn включає елементи геймифікації, створюючи позитивне та мотиваційне середовище для навчання.',
      'Завдяки смартфону Ackurat Learn дозволяє користувачам вивчати мови в будь-який час і в будь-якому місці, надаючи рішення для поціновувачів вивчення мови, які завжди в русі.',
      'Крім розширення словникового запасу і вивчення граматики, Ackurat Learn сприяє комплексному вивченню мови. Додаток пропонує всебічний підхід до розширення словникового запасу, вивчення граматики та підвищення рівня володіння мовою в одному застосунку.'
    ],
    result: 'Ackurat Learn - динамічна система вивчення мови, що забезпесує захопливе та ефективне навчання. Додаток пропонує гнучке навчання за допомогою флеш-карток, гри "Відповідність" і вправ для вимови. Продуманий у боротьбі з нудьгою та втратою мотивації, Ackurat Learn забезпечує легке, приємне і адаптивне вивчення мови, що відповідає заповненим розкладам.'
  },
  {
    id: "shopping_list",
    type: ["Ordering Systems", "Utils", "UI/UX", "iOS"],
    title: "Shopping list",
    subtitle: "Вдосконалюйте ваш процес покупок",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1708963243/tetalab%20site/Cover_2_o2xykn.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176554/tetalab%20site/Slide_3_ovtotw.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709112202/tetalab%20site/Screen_fskhju.png",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535433/Projects/Phones_mockup_-_Shopping_list_1_ldmzs1.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709300810/tetalab%20site/Screens_tjqujy.jpg",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Мобільний додаток, розроблений для забезпечення користувача простими та базовими можливостями при створенні списків, додаванні нових елементів та обміні списками з друзями та родиною.",
    description: "До походу в магазин зазвичай люди складають список покупок у письмовому вигляді, або хоча би в голові. Але іноді шопінг може викликати стрес, тому найкраще вирішення цієї проблеми - дозволити додатку для списку покупок взяти на себе всю роботу.\n" +
      " \n" +
      "Shopping List - мобільний додаток, розроблений для спрощення створення списків покупок, додавання нових товарів, а також для обміну цими списками між друзями та членами сім'ї.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/127122541/Shopping-Lists"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.IOS,
          PLATFORMS.ANDROID
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.PYTHON,
          TECHNOLOGIES.C,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.PCB,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.FOUR_WEEKS
        ]
      },
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Розробити візуально привабливий та зручний для користувача інтерфейс.',
      'Підвищити використання додатку користувачами, зробивши його невід’ємним повсякденним інструментом.',
      'Вирішити проблему створення ефективної системи управління списками.',
      'Забезпечити безперебійну інтеграцію на різних платформах та пристроях.'
    ],
    resumes: [
      'Розробили мобільний додаток, орієнтований на покращення життя користувачів за допомогою полегшення створення списків, додавання позицій та легкого обміну з друзями і родиною.',
      'Створили приємну атмосферу за допомогою яскравих кольорів та інтуїтивно зрозумілого дизайну, що забезпечує легку навігацію без додаткового навчання.',
      'Надали користувачам можливість створювати нові списки покупок, заповнювати їх товарами та безперешкодно ділитися ними зі своїм оточенням.',
      'Реалізували просту категоризацію і можливість групувати товари в менші групи для підвищення зручності користувачів.',
      'Впровадили функцію миттєвого обміну списками, що дозволяє користувачам вибирати отримувачів безпосередньо зі своїх контактів або запрошувати їх електронною поштою.',
      'Забезпечили інтеграцію на різних платформах та пристроях.'
    ],
    result: 'Shopping List - це інноваційний мобільний додаток, розроблений для забезпечення основних потреб користувачів. Вони можуть швидко складати списки необхідних товарів, відстежувати, що вже куплено, а що ще потрібно придбати. Додаток має зручний для користувача інтерфейс та спрощує додавання нових позицій до списків, а також дозволяє ділитися ними з друзями та родиною.'
  },
  {
    id: "hodor_keyboard",
    type: ["Entertainment", "UI/UX", "Android"],
    title: "Hodor Keyboard",
    subtitle: "Обмінюйтеся секретними повідомленнями",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709548824/hodor_cover_qhgpti.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549021/Screen_kidauu.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549202/What_we_did_1_q3wa6a.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177267/tetalab%20site/hodor_z9rewn.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709549308/Phones_mockup_-_Hodor_Keyboard_illjax.jpg",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "З додатком Hodor Keyboard ви зможете без зусиль кодувати та декодувати свій текст і обмінюватися секретними повідомленнями, які ніхто інший не зможе прочитати.",
    description: "З додатком Hodor Keyboard ви легко зможете кодувати та декодувати свій текст і обмінюватися секретними повідомленнями, які ніхто інший не зможе прочитати.\n" +
      " \n" +
      "Включивши шифрування Hodor, ви можете писати все, що захочете, і наша клавіатура автоматично закодує ваше повідомлення, використовуючи слово «Hodor» або будь-яке інше слово, яке ви оберете. Ви можете скористатися «Декодером», щоб прочитати закодовані повідомлення, вставивши закодоване повідомлення.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123378515/Hodor-Keyboard-UIUX-Design"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.KEYBOARD,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Основною задачею було забезпечити безперервне кодування і декодування тексту. Це передбачало створення функціональних можливостей, які дозволяють користувачам легко шифрувати і розшифровувати повідомлення, використовуючи обране ключове слово.',
      'Метою було створення програми для обміну секретними повідомленнями, які неможливо прочитати стороннім людям, забезпечуючи конфіденційність і захист вмісту розмови.',
      'Надання користувачам можливості налаштовувати параметри шифрування, такі як вибір мови, колір теми та ключові слова для кодування повідомлень. Налаштувати ці параметри можна на головному екрані.',
      'Розробка додатку з інтуїтивно зрозумілим інтерфейсом, де користувачі могли налаштовувати клавіатуру, вибирати мови, кольорові теми та ключові слова для кодування і декодування повідомлень як на основному екрані, так і на екрані «Декодер».',
    ],
    resumes: [
      'Ми розробили додаток Hodor Keyboard, щоб дозволити користувачам кодувати та декодувати текст, сприяючи обміну секретними повідомленнями, непрочитними для інших.',
      'Користувачі можуть писати, активуючи шифрування Hodor. Клавіатура автоматично кодує повідомлення, використовуючи обране ключове слово, таке як «Hodor» або будь-яке інше слово за вибором користувача.',
      'Функція «Декодер» дозволяє користувачам легко читати закодовані повідомлення.',
      'Додаток Hodor Keyboard призначений для ситуацій, коли користувачі хочуть приховати вміст своїх розмов від сторонніх. Мобільний додаток, обладнаний власною клавіатурою, автоматично кодує весь текст та повідомлення, використовуючи ключове слово, обране користувачем, для підвищення приватності.',
      'Додаток має зручний та зрозумілий інтерфейс. Ключові компоненти включають головний екран для налаштування клавіатури з вибором мови, кольоровими темами та ключовими словами для кодування повідомлень, а також екран «Декодер», завдяки якому відбувається декодування повідомлень.',
    ],
    result: 'Додаток Hodor Keyboard забезпечує кодування та декодування для приватного обміну повідомленнями. Активація шифрування Hodor дозволяє автоматично кодувати повідомлення з вибраним користувачем ключовим словом, забезпечуючи персоналізовану конфіденційність. Функція «Декодер» дозволяє легко читати закодовані повідомлення, сприяючи зручному користувацькому досвіду. З власною клавіатурою та зручним інтерфейсом, додаток автоматизує кодування тексту та повідомлень, забезпечуючи безпечну та персоналізовану платформу для комунікації.'
  },
  {
    id: "LiveAir",
    type: ["IoT", "UI/UX", "Android", "iOS", "FrontEnd", "Backend"],
    title: "LiveAir",
    subtitle: "Оптимізація моніторингу якості повітря",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Cover_3_wgmslb.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Screen_2_mcqt3r.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176958/tetalab%20site/air_yrspjt.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360847/Main/Group_1151504_sh1ubi.svg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709550295/Phones_mockup_-_LiveAir_pqk32x.jpg",
    reading: "9",
    date: "верес 24, 2020",
    previewDescription: "З цим додатком ви можете керувати пристроями для виявлення забруднень та отримувати не лише загальну інформацію про них, але й співвідношення кожного забрудника.",
    description: "Останнім часом навколишнє середовище швидко змінюється, і якість повітря є важливою частиною цього процесу. Забруднення повітря - серйозна загроза для здоров'я, яку потрібно контролювати.\n" +
      " \n" +
      "Ми розробили 'LiveAir' - мобільний додаток, веб-платформа та сервер. \n" +
      " \n" +
      "Ця система допомагає керувати пристроями для виявлення забруднення та забезпечує високорозширений моніторинг якості повітря, візуалізуючи всю важливу інформацію у вигляді детальних та зрозумілих графіків.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123373415/LiveAir"
      }
    ],
    clientDescription: "Наш партнер, маючи прагнення боротися з екологічними проблемами, звернувся до нас за допомогою у розробці додатку. \n"  +
      " \n" +
      "Зосередившись на здоров’ї людей та боротьбі з забрудненням повітря, він створив ідею \"LiveAir\" як рішення. Разом ми маємо на меті зробити турботу про довкілля більш доступною за допомогою зручних технологій.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356138/Main/Group_1000003093_ccedf8.svg",
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
          PLATFORMS.WEB
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.C,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.WIFI,
          TECHNOLOGIES.NODE_MCU,
          TECHNOLOGIES.FIREBASE,
          TECHNOLOGIES.REACT,
          TECHNOLOGIES.NODEJS
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.SIX_WEEKS,
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.QA
    ],
    challenges: [
      'Розробка стратегій для подолання швидких змін у навколишньому середовищі, визнання важливої ролі якості повітря в цих процесах.',
      'Зменшення забруднення повітря як значущої загрози для здоров\'я, наголошуючи на необхідності ефективних контрольних заходів.',
      'Інтеграція мобільного додатка, веб-платформи та сервера для управління пристроями для виявлення забруднення та надання детального високоточного моніторингу якості повітря.',
      'Проєктування системи для візуалізації важливої інформації за допомогою детальних та зрозумілих графіків.',
    ],
    resumes: [
      'Створили комплексне рішення — LiveAir — з мобільним додатком, веб-платформою та сервером для вирішення екологічних викликів.',
      'Інтегрували передові технології в пристрої для виявлення забруднення, забезпечивши управління за допомогою мобільного додатка LiveAir та веб-платформи.',
      'Забезпечили комплексний моніторинг якості повітря за допомогою високороздільних візуалізацій LiveAir, представивши важливу інформацію у вигляді легко зрозумілих графіків.',
      'Реалізували важливу функцію в LiveAir — надсилання користувачам сповіщення в реальному часі, що дозволяє швидко реагувати на потенційні загрози якості повітря.',
      'Оснастили LiveAir здатністю не лише надавати загальний огляд забруднення, а й надавати детальний аналіз кожного забруднювача для більш глибокого розуміння проблеми.',
    ],
    result: 'LiveAir, наше інноваційне рішення, вирішує глобальний виклик моніторингу забруднення повітря за допомогою мобільного додатка, веб-платформи та сервера. Шляхом ефективного управління пристроями для виявлення забруднення, LiveAir надає користувачам моніторинг якості повітря високої, представлений у детальних графіках. У сучасному світі, де забруднення повітря становить значну загрозу для здоров\'я по всьому світу, LiveAir забезпечує сповіщення в реальному часі, дозволяючи користувачам активно моніторити забруднення повітря.'
  },
  {
    id: "fun_recycle",
    type: ["Education", "UI/UX", "Android", "iOS", "FrontEnd", "Flutter"],
    title: "Fun Recycle",
    subtitle: "Вважаєте сортування сміття нудним? Давайте зробимо його веселим",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_cover_eytek8.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551340/fun_recycle_Screen_hidais.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_resume_nfmpbu.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709551341/fun_recycle_background_mpom1d.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177008/tetalab%20site/recycle_yuzkur.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Мобільний додаток розроблений для допомоги у розумінні впливу переробки відходів на навколишнє середовище та того, як кожен може зробити свій внесок у збереження екологічного благополуччя.",
    description: "Безпека довкілля є однією з найважливіших цілей у нашому житті. Наш обов’язок - зберігати природу та запобігати її забрудненню.\n" +
      " \n" +
      "Fun Recycle - це мобільний додаток, який полегшує відстеження вторинної переробки відходів та надає корисну інформацію. З цим додатком користувачі можуть записувати кількість відходів, які ви вторинно переробляють, і обирати конкретні періоди часу для отримання детальних звітів. Крім того, вони можуть дізнатися про екологічний вплив вторинної переробки та отримувати винагороди за активну участь у процесі вторинної переробки.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/123419183/Fun-Recycle"
      }
    ],
    clientDescription: "Наш клієнт, прагнучи підвищити обізнаність про довкілля та сприяти свідомому управлінню відходами, орієнтується на два основних сегментф: екологічно свідомих осіб, які шукають передові інструменти для відстеження та мотивації, та новачків, включаючи дорослих і дітей, які прагнуть запровадити сталі практики. \n"  +
      " \n" +
      "FUN Recycle орієнтований на ці дві аудиторії завдяки своїй зручній платформі, яка сприяє формуванню екологічно свідомої спільноти через захоплюючі функції та освітні інструменти.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356137/Main/image_1_hsdqxk.svg",
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
          PLATFORMS.WEB,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.REACT,
          TECHNOLOGIES.ROOM
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Нашою основною метою було посприяти збереженню довкілля та запобігти забрудненню завдяки додатку, спрямованому на просування покращення довкілля.',
      'Ключовим завданням було створення додатку Fun Recycle з інтуїтивним інтерфейсом, що забезпечує доступність для користувачів, які цікавляться відстеженням своїх дій щодо вторинної переробки відходів.',
      'Команда прагнула підвищити обізнаність про вторинну переробку через додаток, надаючи користувачам повну інформацію про екологічні переваги вторинної переробки відходів.',
      'Однією з основних цілей було показати користувачам екологічний вплив вторинної переробки відходів. Додаток виступає як ресурс, який інформує людей про позитивні наслідки їхніх зусиль у сфері вторинної переробки.',
    ],
    resumes: [
      'Ми розробили концепцію та створили Fun Recycle - мобільний додаток, присвячений спрощенню відстеження вторинної переробки відходів та підвищенню обізнаності про її екологічний вплив.',
      'Для покращення досвіду користувачів ми розробили в Fun Recycle інтуїтивний інтерфейс, що дозволяє легко відстежувати свої дії щодо переробки.',
      'Fun Recycle дозволяє користувачам позначати та налаштовувати свої дії з переробки, обираючи конкретні періоди для повного огляду свого екологічного внеску.',
      'Через додаток ми впровадили освітні функції, що дозволяють користувачам дізнатися про екологічний вплив вторинної переробки та сприяють глибшому розумінню практик розумного споживання.',
      'Для стимулюяції активної участі у переробці, ми ввели систему винагород, щоб мотивувати користувачів, які активно займаються вторинною переробкою.',
      'Наша увага була спрямована на надання користувачам можливості розуміти особистий внесок у збереження довкілля та сприяти активній участі в загальних зусиллях для збереження планети.',
    ],
    result: 'Fun Recycle - це мобільний додаток, зроблений для збереження довкілля, який надає інтуїтивно зрозуміле відстеження переробки відходів та інформацію про екологічний вплив вторинної переробки. Користувачі можуть легко відстежувати та налаштовувати свої дії. Завдяки освітньому інтерфейсу та системі винагород стимулюється відповідальний підхід до вторинної переробки сміття.'
  },
  {
    id: "trailer_Watchdog",
    type: ["IoT", "UI/UX", "Android", "iOS"],
    isDark: true,
    isCaseStudy: true,
    title: "Trailer Watchdog",
    subtitle: "Забезпечте безпеку свого трейлера",
    thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715363000/trailer_watchdog_cover_krmgvh.jpg",
    logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715603121/trailer_watchdog_1_r2lgsv.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/Frame_otjuny.svg",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535422/Projects/Phones_mockup_-_Trailer_Watchdog_j2yzvs.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180624/tetalab%20site/security_zsm3rs.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Додаток покращує управління трейлером. Завдяки даному рішенню відбувається безперешкодний моніторинг температури вісей та надсилання важливих сповіщень.",
    description: "Додаток Trailer Watchdog створений для зв'язку та відображення даних, призначений для зручного контролю даних пристроїв Bluetooth. Слідкуйте за температурою вісі трейлера, отримуйте сповіщення та зберігайте важливу інформацію за допомогою додатка. \n" +
      " \n" +
      "Розробка відбувалася в середовищі Android SDK з використанням сучасної мови програмування Kotlin. Використовувалися технології Bluetooth. Реалізовано прийом та відображення даних з Bluetooth-пристроїв, відстеження критичних температур, надсилання сповіщень, а також збереження масиву даних з сеансу в пам'яті телефону.",
    clientDescription: "Наприкінці 2021 року ми отримали запит на допомогу у розробці Android-додатка та інтеграції пристроїв Інтернету речей (IoT) для системи безпеки американської компанії. Основна ідея їхнього продукту - відстеження показників трейлерів. Якщо виявляються відхилення від норми, власника сповіщають про потенційні проблеми.",
    clientDescriptionItems: [
      'Моніторинг ключових показників, таких як температура вісі, тиск у шинах та інші дані.',
      'Візуалізацію ключових метрик у реальному часі.',
      'Отримання сповіщень про проблеми.',
      'Зберігання інформації про поїздки.',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715603121/trailer_watchdog_2_rqn45r.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/112477221/TrailerWatchdog"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.PCB,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.SIX_WEEKS,
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.QA
    ],
    challenges: [
      'Розробка додатку, який зробить обслуговування трейлеру більш комфортним.',
      'Збір та аналіз даних, які можна зберігати під час поїздки.',
      'Проектування мобільного додатку для керування розумними пристроями.',
      'Створення зрозумілих візуалізацій даних.',
      'Управління системою трейлера за допомогою смартфона.',
    ],
    resumeDescription: "Ми розробили рішення для зв'язку та відображення даних, яке спрямоване на ефективний контроль даних пристроїв Bluetooth. Нашою основною метою було відстеження температур вісі трейлера та надання користувачам своєчасних сповіщень. Процес розробки відбувався в середовищі Android SDK з використанням сучасної мови програмування Kotlin. Ми інтегрували технології Bluetooth для прийому та відображення даних від підключених пристроїв. Ключові функціональні можливості включають відстеження критичних температур та сповіщення у необхідний момент. Крім того, ми розробили додаток таким чином, щоб зберігати масив даних з кожної сесії у пам'яті телефону, забезпечуючи легкий доступ для користувачів до моніторингу температурних показників.",
    result: 'Trailer Watchdog пропонує безпеку та надійність для вашого причепа завдяки нашому інноваційному з\'єднанню.',
    resultItems: [
      'Моніторинг температури вісей та отримання сповіщень у реальному часі забезпечує власника інформацією про стан вашого причепа в будь-який час і в будь-якому місці.',
      'Додаток має інтуїтивний інтерфейс, який полегшує керування та доступ до важливої інформації, забезпечуючи зручний досвід для користувачів.',
      'Встановлення надійного з\'єднання з пристроями трейлера за допомогою передових технологій Bluetooth гарантує надійний прийом та відображення даних.',
      'Trailer Watchdog зберігає дані з кожної сесії моніторингу в пам\'яті телефону, що дозволяє користувачам відстежувати та аналізувати метрики температури.',
      'Додаток динамічно відображає дані конфігурації трейлера, надаючи користувачам візуальне представлення критичних метрик температури для покращеного моніторингу та прийняття рішень.',
    ],
    systemIncludes: [
      'Надійне з\'єднання по Bluetooth, що забезпечує комунікацію між додатком Trailer Watchdog та Bluetooth-пристроями, приєднаними до причепа.',
      'Датчики для моніторингу та відображення температур вісі в реальному часі, що надають користувачам важливі відомості про стан їхнього трейлера.',
      'Інтеграція вдосконаленої системи сповіщень, яка доставляє користувачам повідомлення, коли виявляються критичні температури, забезпечуючи швидку реакцію на потенційні проблеми з причепом.',
      'Ефективні механізми зберігання даних, що дозволяють системі зберігати та організовувати інформацію з кожної сесії, полегшуючи отримання та аналіз архіву метрик температури.',
      'Інтуїтивний та зручний інтерфейс, який дозволяє користувачам легко керувати своєю системою трейлера через свої смартфони, підвищуючи загальний рівень зручності та доступності для користувачів.',
    ],
  },
  {
    id: "flora",
    type: ["IoT", "UI/UX", "Android", "AI", "iOS", "Education"],
    isDark: true,
    isFlora: true,
    isCaseStudy: true,
    title: "Plant Care App",
    subtitle: "Ваш незамінний помічник з догляду за рослинами",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717061355/tetalab%20site/pexels-soumil-kumar-735911_2_ehvv3k.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062125/tetalab%20site/Group_1000003092_fg5ro7.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/All_steps_illustration_rjtpbc.svg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062322/tetalab%20site/Phones_mockup_-_Flora_qajlf8.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180433/tetalab%20site/flora_jbrse0.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Our plant care app revolutionizes plant nurturing, offering unparalleled convenience and support to users.",
    description: "Цей проєкт спрямований на розробку мобільного додатку, який виведе на новий рівень догляд за рослинами. Ми прагнули створити платформу, яка задовольняє різноманітні потреби любителів рослин з будь-яким рівнем знань.\n" +
      " \n" +
      "Наш проєкт охоплює широкий спектр функціоналу для надання користувачам нових можливостей: ідентифікація рослин, персоналізованої поради з догляду, залучення спільноти, гейміфікація тощо. Використовуючи останні досягнення в галузі Інтернету речей та мобільних технологій, ми маємо на меті встановити нові стандарти для додатків з догляду за рослинами.",
    clientDescription: "Понад рік тому до нас звернулися з пропозицією зробити свій внесок у розробку додатка для iOS, інтегрувати Bluetooth-пристрої та завершити розробку додатку для Android для провідної стартап-компанії з США, що спеціалізується на рішеннях для догляду за рослинами. Вони побачили зростаючий попит на зручні та технологічні інструменти для спрощення догляду за рослинами.",
    clientDescriptionItems: [
      'Користувацькі пристрої для догляду за рослинами',
      'Мобільні додатки для платформ Android та iOS',
      'Спільноту користувачів, які доглядають за рослинами',
      'Додаткові послуги для допомоги у догляді за рослинами',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1717062179/tetalab%20site/Group_1000003093_y3sanr.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/112477221/TrailerWatchdog"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.FIREBASE,
          TECHNOLOGIES.IN_APP_PURCHASE,
          TECHNOLOGIES.BLUETOOTH
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.PHOTOSHOP,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.NINE_WEEKS
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Підвищення якості дизайну та процесу розробки для оптимізації ефективності',
      'Покращення документації та технічних специфікацій для забезпечення ясності та точності протягом життєвого циклу проєкту',
      'Забезпечення комунікації між командами дизайну та розробки в різних часових поясах',
      'Проектування та розробка додатка для Android',
    ],
    resumeDescription: `Ми розпочали ряд стратегічних ініціатив та впровадили різноманітні рішення для ефективного вирішення викликів. Наша команда працювала з різними аспекти проєкту, забезпечуючи успішне втілення та досягнення встановлених цілей. Використовуючи наші знання та експертизу, ми розробили стратегії для оптимізації процесів. Нашою основною метою було забезпечити ефективне використання ресурсів та досягнення найкращих результатів для наших клієнтів.`,
    resumes: [
      'Надали команду в тому ж часовому поясі для швидшої комунікації та розробки.',
      'Надати додаткові правила для кращої організації команди.',
      'Проаналізували додаток для iOS та надали план розробки додатку для Android Цей етап покращив наше загальне розуміння існуючого проекту, і ми створили початкову документацію.',
      'Використали сучасну архітектуру програмного забезпечення для мобільних додатків. Це призвело до зменшення кількості проблем і прискорення нових розробок.',
    ],
    result: 'Наш додаток з догляду за рослинами надає допомогу та зручністьу догляді, з фокусом на на унікальні потреби та уподобання любителів рослин.',
    resultItems: [
      'Цього року додаток для iOS отримав понад 110 тис. користувачів',
      'Більше ніж 65 тис. доларів було зібрано за допомогою Kickstarter та участі в стартап-конкурсах завдяки змінам у додатку',
      'У топ-25% за збереженням щомісячної підписки серед всіх додатків для iOS',
      'Обсяг доходів продовжує зростати на 45% щомісяця з моменту запуску додатка.',
    ],
    systemIncludes: [
      'Користувацькі пристрої для догляду за рослинами, які являють з себе спеціалізовані апаратні рішення, призначені для оптимізації здоров\'я рослин.',
      'Мобільні додатки для платформ Android та iOS.',
      'Спільноту користувачів, що доглядають за рослинами, де вони можуть спілкуватися з іншими любителями рослин',
      'Геймифікацію, яка занурює користувачів у розважальний догляд за рослинами. Вони можуть заробляти винагороди, доглядаючи свої рослини, роблячи кожне цвітіння незабутньою подією',
    ],
  },
  {
    id: "lviv_city_guide",
    type: ["Tourism", "UI/UX", "Android", "iOS"],
    title: "Lviv City Guide",
    subtitle: "Екскурсійні гіди містами - ваша унікальна пригода",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552359/lviv_city_guide_Cover_fkkunx.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552359/lviv_city_guide_Screen_rebjx0.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552360/lviv_city_guide_Screens_hzyw0o.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709552360/lviv_city_guide_background_u1pobw.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177050/tetalab%20site/guide_t4x8sq.png",
    reading: "9",
    date: "Sep 24, 2020",
    previewDescription: "Мобільний додаток призначений для допомоги користувачам ефективно планувати свої подорожі, надаючи корисну інформацію та зручні маршрути для комфортного досвіду подорожування.\n",
    description: "Багато туристичних міст не можуть запропонувати екскурсоводів, які б володіли кількома мовами. Їм потрібні екскурсоводи, що мають перелік цікавих місць, короткі описи та їх місцезнаходження.\n" +
      " \n" +
      "Ми розробили додаток, який може бути адаптований для різних міст. Користувачі можуть завантажувати інформацію про цікаві місця, екскурсії та інші зручності.\n" +
      " \n" +
      "В результаті міста можуть надавати мобільних екскурсоводів з інформацією про екскурсії, розваги, послуги та надавати карти з навігацією.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/92597467/City-Guide-Case-Study"
      },
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535423/Projects/youtube-brands_oq9mew.svg",
        link: "https://www.dropbox.com/s/omblun0rsi1ese3/CityGuide_live_video.mp4?dl=0"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.OSM,
          TECHNOLOGIES.ROOM,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.DESKTOP_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Для подолання мовного бар\'єру у туристичних містах розроблено багатомовний посібник для відвідувачів з різного рівня мовними знаннями.',
      'Створено універсальний додаток, адаптований до конкретних вимог різних міст та їх унікальних пам\'яток.',
      'Додано можливість користувачам активно робити свій внесок, завантажуючи інформацію про цікаві місця, екскурсії та інші корисні деталі, сприяючи створенню загальної платформи.',
      'Розроблено програму "City Guide", щоб допомогти користувачам ефективно планувати подорожі, надаючи корисну інформацію та забезпечуючи зручні маршрути для комфортного подорожування.',
    ],
    resumes: [
      'Розроблено багатомовний додаток для подолання мовних бар\'єрів у туристичних напрямках.',
      'Створені посібники з переліком цікавих місць з короткими описами та точними місцезнаходженнями.',
      'Розроблений гнучкий додаток, який може легко адаптуватися до унікальних вимог різних міст.',
      'Впроваджено зручну для користувачів платформу, що дозволяє їм активно співпрацювати, завантажуючи інформацію про цікаві місця, екскурсії та інші корисні деталі.',
      'Містам надана можливість впровадити мобільні посібники з інформацією про екскурсії, розваги, послуги та карти навігації.',
      'Впроваджено функції для допомоги користувачам у керуванні часом та ефективному плануванні маршрутів, забезпечуючи зручний і приємний досвід подорожування.',
      'Користувачам надана можливість ділитися своїми моментами та враженнями з іншими.',
    ],
    result: 'City Guide полегшує подорожі за допомогою багатомовних посібників, комплексної інформації та зручного додатку. Користувачі активно роблять свій внесок, надаючи містам можливість впроваджувати мобільні посібники з деталями про екскурсії, розваги, послуги та навігацію. Ця програма допомагає планувати подорожі, забезпечуючи зручний інструмент для ефективного вивчення локацій, управління часом, планування маршрутів та спільного досвіду. City Guide покращує подорожі, роблячи їх багатомовними та персоналізованими.'
  },
  {
    id: "museum_guide",
    type: ["Tourism", "UI/UX", "Android", "iOS", "Backend"],
    isDark: true,
    title: "Museum Guide",
    subtitle: "Колекція екскурсійних гідів для різних музеїв",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Cover_me5poj.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Screen_ab2ofi.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_Screens_dhemwf.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709553370/museum_guide_background_fa30ht.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177122/tetalab%20site/museum_xrviia.png",
    previewDescription: "Пропонуючи багатомовні аудіогіди, додаток надає можливість музеям надавати повну інформацію про експозиції.",
    description: "До музеїв приходять відвідувачі з різних країн. Туристи хотіли б мати аудіогіди на своїх мовах. \n" +
      " \n" +
      "Ми розробили додаток-аудіогід, який можна налаштувати для використання у різних музеях. \n" +
      " \n" +
      "У результаті, музеї можуть надавати відвідувачам інформацію про експозиції разом з аудіо-доріжками.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/94423251/Museum-Guide"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.MEDIA_PLAYER,
          TECHNOLOGIES.FIREBASE
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.FRONTEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Забезпечити підтримку для широкого спектру мов, щоб відвідувачі могли вибрати необхідну мову для аудіогіда.',
      'Музеї можуть мати різні вимоги до вмісту аудіогіда, тому додаток повинен бути гнучким для швидких змін та модифікацій.',
      'Створити додаток, який може ефективно працювати як для великих, так і для малих музеїв, забезпечуючи оптимальний досвід для відвідувачів, незалежно від розміру установи.',
      'Розробити додаток Museum Guide для надання повноцінних аудіогідів з детальною інформацією про експозиції.',
    ],
    resumes: [
      'Розроблено додаток для аудіогідів, який враховує потреби музеїв у багатомовності, дозволяючи туристам досліджувати експозиції на обраних мовах.',
      'Створено гнучкий додаток, який дозволяє безперешкодно вносити зміни, щоб задовольняти конкретні потреби різних музеїв, забезпечуючи гнучкість у використанні додатку.',
      'Впроваджено функції, що надають музеям можливість створювати повноцінні гіди з детальною інформацією про експозиції, покращуючи загальний досвід відвідувача.',
      'Створено Museum Guide - різноманітну колекцію гідів, які адаптовані для різних музеїв, пропонуючи як короткі, так і розширені версії для врахування унікальних особливостей різних культурних установ.',
      'Розроблено дві різні версії додатку, які враховують потреби як малих, так і великих музеїв. Базова та розширена версії забезпечують оптимальну функціональність для широкого спектру культурних установ.',
      'Додаток Museum Guide, завдяки нашим зусиллям, підвищує залученість відвідувачів, надаючи налаштовані аудіогіди, що знімають мовні бар\'єри, роблячи культурні події доступнішими та приємнішими для різноманітної аудиторії.',
    ],
    result: 'Покращуйте глобальні музейні враження за допомогою нашого універсального додатка Museum Guide, який пропонує багатомовні аудіогіди. Додаток надає можливість музеям надавати повну інформацію про експозиції та аудіодоріжки. Доступний у базовій та розширеній версіях, він відповідає відмінним потребам різних культурних установ, роблячи культурні заходи доступнішими для відвідувачів по всьому світу.'
  },
  {
    id: "game-bacterial_war",
    type: ["Entertainment", "UI/UX", "Android"],
    isFlora: true,
    isDark: true,
    title: "Game - Bacterial War",
    subtitle: "Мобільна стратегічна гра",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180193/tetalab%20site/Bg_xbp2fv.png",
    logo_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535438/Projects/Group_1279_cseyvh.svg",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535431/Projects/Phones_mockup_-_Bacterial_War_sbyxg7.png",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535431/Projects/Phones_mockup_-_Bacterial_War_sbyxg7.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177358/tetalab%20site/bacterial_jqeah7.png",
    previewDescription: "Протягом 40 рівнів користувачі прокладають шлях через динамічні виклики, хитрощами обходять ворогів і перемагають у цьому фантастичному мікрокосмі.",
    description: "Багато хто з нас опинявся в ситуаціях, коли необхідно почекати на зустріч, прийом чи інші події. У цьому випадку люди часто грають у мобільні ігри, щоб не нудьгувати. \n" +
      " \n" +
      "Ми розробили мобільну стратегічну гру у жанрі стратегія/будівництво колоній.\n" +
      " \n" +
      "У цій грі користувач може контролювати бактеріальні колонії і боротися за простір і ресурси. Протягом гри користувач зустрічає нових ворогів і нейтральні колонії. Гра включає 40 рівнів різної складності, надаючи гравцям нові виклики. ",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/92617529/Bacterial-War-game-Case-Study"
      },
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535423/Projects/youtube-brands_oq9mew.svg",
        link: "https://www.youtube.com/watch?v=WCdyWg_opp4"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.MEDIA_PLAYER,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.DESKTOP_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Створення захопливої мобільної гри у жанрі стартегії, надаючи користувачам унікальний досвід у стратегії та будівництві колоній.',
      'Створення динамічного ігрового середовища, де користувачі могли б контролювати колонії бактерій, брати участь у стратегічних битвах за територію та важливі ресурси.',
      'Для збереження зацікавленості гравців ми інтегрували нові виклики у формі ворогів та нейтральних колоній протягом 40 рівнів гри, кожен з яких має різні труднощі.',
      'Створення інтуїтивно зрозумілого інтерфейсу для забезпечення гравцям зручного ігрового досвіду.',
    ],
    resumes: [
      'Розробили захопливу мобільну гру, яка поєднує елементи стратегії та будівництва колоній. Це унікальне об’єднання жанрів відрізняє нашу гру, надаючи гравцям інноваційний та захоплюючий ігровий досвід.',
      'Гравці можуть контролювати бактеріальні колонії та керувати ними у стратегічних битвах за простір і важливі ресурси. Це створює динамічне середовище з багатьма викликами',
      'Гра має 40 рівнів, тому користувачі мають справу з прогресуючими викликами, зустрічають нових ворогів та взаємодіють з нейтральними колоніями. Кожен рівень має різні складносщі, забезпечуючи захоплюючий досвід, який зацікавлює та розважає гравців.',
      'Наша гра запрошує гравців до фантастичної подорожі, де деталі бактеріального життя стають захоплюючую основою для стратегічних завоювань та відкриттів.',
      'Щоб досягти успіху, гравцям потрібно розробити стратегію та створити шляхи між своїми колоніями та колоніями супротивників. Будівництва доріг додає певну складність, вимагаючи обдуманого планування для досягнення перемоги.',
      'Остаточною метою є досягнення конкурентної переваги над ворожими колоніями завдяки стратегічним зусиллям. Зробивши обдумані кроки, гравці прокладають шлях до перемоги, закріплюючи перемогу над бактеріальним світом.',
    ],
    result: 'Остаточною метою є досягнення конкурентної переваги над ворожими колоніями завдяки стратегічним зусиллям. Зробивши обдумані кроки, гравці прокладають шлях до перемоги, закріплюючи перемогу над бактеріальним світом.'
  },
  {
    id: "greenHouse_control_system",
    type: ["IoT", "UI/UX", "Android", "iOS", "Backend"],
    title: "GreenHouse control system",
    subtitle: "Прототип автоматизованої системи для теплиць",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709299797/tetalab%20site/AckuratCover_gbhnbx.png",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709299896/tetalab%20site/AckuratScreen_glf1pu.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709301690/tetalab%20site/Screens_1_ikeiup.jpg",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709300169/tetalab%20site/Phones_mockup_-_GreenHouse_control_system_uifiaz.jpg",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718176823/tetalab%20site/green_penfyz.png",
    previewDescription: "Завдяки автоматизації процесів система оптимізує сільськогосподарську продуктивність, надаючи спрощені рішення для ефективного та ефективного управління рослинами.",
    description: "Фермери мають у використанні багато теплиць. Зазвичай автоматизовані теплиці не можуть забезпечити можливість віддалено підтримувати і контролювати параметри системи.\n" +
      " \n" +
      "Ми розробили прототип автоматизованої системи теплиці, з мобільним додатком та сервером. \n" +
      " \n" +
      "Користувач може відстежувати стан системи та вручну керувати її поведінкою. Апаратне забезпечення зчитує дані з датчиків та відправляє їх по Wi-Fi на віддалений сервер Firebase.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/96341613/Greenhouse-Controller"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.C,
          TECHNOLOGIES.BLUETOOTH,
          TECHNOLOGIES.NODE_MCU,
          TECHNOLOGIES.FIREBASE,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.INDESIGN,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    clientDescription: "Наші клієнти шукали інноваційне IoT рішення для управління теплицею, включаючи автоматичний контроль. Їм була необхідна інтуїтивно зрозуміла панель управління зі списком рослин та налаштуваннями, що можна змінювати (температура, світло). Також необхідна була можливість зручно додавати нові рослини до системи.  \n"  +
      " \n" +
      "Наші клієнти прагнули отримати зручний додаток для моніторингу в реальному часі та точного контролю параметрів навколишнього середовища для кожної рослини у своїй теплиці.",
    clientDescriptionImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718356137/Main/Group_152_axj8yh.svg",
    challenges: [
      'Розробка надійної системи для віддаленого моніторингу умов в теплицях.',
      'Інтеграція функцій керування в мобільний додаток, забезпечуючи безперешкодну комунікацію з апаратним забезпеченням та сервером.',
      'Створення користувацького інтерфейсу, з урахуванням потреб системи в обслуговуванні різноманітного кола користувачів, від фермерів до професійних агрономів.',
      'Встановлення системи реагування в реальному часі для контролю користувача над умовами в теплиці, особливо коли потрібні швидкі корекції.',
    ],
    resumes: [
      'Створили прототип автоматизованої системи теплиці, щоб зменшити обмеження традиційних автоматизованих теплиць.',
      'Розробили та впровадили надійну систему, яка поєднує апаратне забезпечення, мобільний додаток та сервер.',
      'Розробили інтуїтивний та зручний для користувача інтерфейс мобільного додатка для легкої навігації та керування.',
      'Інтегрували функції керування, що дозволяють користувачам безпосередньо керувати поведінкою системи теплиці.',
      'Забезпечили безперервний зв\'язок між мобільним додатком, апаратним забезпеченням та сервером.',
      'Реалізували механізм для ефективного зчитування даних датчиків апаратним забезпеченням.',
      'Надали користувачам можливість в реальному часі моніторити умови теплиці.',
      'Надали користувачам можливість одразу вносити корективи до системи на основі даних у реальному часі.',
    ],
    result: 'Система керування теплицею Green House змінює догляд за рослинами для фермерів та агрономів завдяки своєму зручному інтерфейсу та комплексній інформації про рослини. Завдяки можливості відстеження даних в реальному часі та керуванню у користувачів є змога приймати обґрунтовані рішення, що зберігає їх час і підвищує впевненість у догляді за рослинами. Завдяки автоматизованим процесам, відбувається оптимізація сільськогосподарської продуктивності, пропонуючи зведене рішення для ефективного та результативного управління рослинами.'
  },
  {
    id: "android_POS",
    type: ["Ordering Systems", "IoT", "UI/UX", "Android", "FrontEnd", "Backend"],
    isDark: true,
    isCaseStudy: true,
    hasSystemArchitectureImg: true,
    title: "Android POS",
    subtitle: "Ефективне управління магазином фруктів та овочів. Розробка автоматизованої POS системи",
    thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715023561/Android_POS_cover_qhsooz.jpg",
    logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715030500/Android_POS_About_Project_w7hlbb.png",
    resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360846/Main/Scheme_jcjbdq.svg",
    bg_img: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535432/Projects/Phones_mockup_-_GreenHouse_control_system_1_ejahwn.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180482/tetalab%20site/android_ls3sda.png",
    previewDescription: "Спростіть свої продажі за допомогою Android POS – швидке зважування, відстеження інвентарю, оптимізовані платежі та швидші розрахунки. \n",
    description: "Магазини постійно шукають нові способи автоматизації процесів та зменшення витрат, часто покладаючись на POS системи.\n" +
      " \n" +
      "Ми брали участь у розробці автоматизованої системи POS для стартапу в ЄС. Система використовує пристрої на базі Android разом з обладнанням та бекенд-сервером.\n" +
      " \n" +
      "Завдяки впровадженню цієї системи адміністратори можуть скоротити час покупок та мінімізувати черги в магазині. Крім того, систему можна розширити для роботи в режимі самообслуговування.",
    clientDescription: "Наша компанія отримала запит на допомогу у розробці апаратного та програмного забезпечення для бельгійського стартапу, спрямованого на автоматизацію невеликих магазинів. Основна ідея проєкту - спростити процес покупок за допомогою самостійного обслуговування та оплати. Система може зменшити витрати та спростити управління магазином.",
    clientDescriptionItems: [
      'Спрощення роботи магазинів, які використовують ваги для вимірювання товарів.',
      'Ведення обліку залишків товарів.',
      'Система управління обладнанням.',
      'Прискорення роботи касирів.',
      'Доступ до статистики та інформації про покупки користувачів.',
    ],
    clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1715066870/About_Client_IMG_Android_POS_davaqz.png",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/94421677/Android-POS"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.PYTHON,
          TECHNOLOGIES.C,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.PCB,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.ILLUSTRATOR
        ],
      },
      {
        term: [
          TERM.NINE_WEEKS
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.ELECTRONIC_ENGINEER,
      TEAM.BACKEND_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Розробка доступного обладнання, яке можна розмістити у магазинах.',
      'Проєктування та розробка надійних додатків для планшетів.',
      'Зручна адмін-панель для управління магазином.',
      'Збірка та налаштування кількох пристроїв для тестування системи.',
      'Проста інтеграція з існуючими системами.',
      'Розробка зручного інтерфейсу та зрозумілої інструкції для встановлення системи, які будуть доступні для людей з нетехнічною освітою.',
    ],
    resumes: [
      'Використали сучасну архітектуру програмного забезпечення для мобільних додатків, яка легко піддається вдосконаленню та підтримці.',
      'Розробили, припаяли та підключили плати для зв’язку планшетів, читачів карток, зовнішніх ваг та квиткових принтерів.',
      'Створили корпуси для пристроїв з використанням 3D-друку.',
      'Розробили веб-адмін-панель та сервер для зв\'язку всіх елементів.',
      'Забезпечили кваліфіковану команду з дизайну та розробки з повним набором навичок для виконання проектів від ідеї до остаточної збірки.',
    ],
    result: 'Автоматизована POS-система - це спеціальна система, яка забезпечує простий та зручний спосіб зважування та оплати фруктів та овочів. Проєкт має багато переваг:',
    resultItems: [
      'Зменшено обсяг роботи касирів або замінено їх.',
      'Клієнти можуть здійснювати покупки без взаємодії з касиром.',
      'Підготовлено проект для оцінки інвесторами.',
      'Середнє очікування у черзі в невеликому магазині з однією касовою машиною становила 4 хвилини при середньому навантаженні магазину, а після впровадження Android POS-системи становила 2,5 хвилини (зменшено на 38%).',
    ],
    systemIncludes: [
      'Android-додаток для керування вагами та корзинами користувачів.',
      'Android-додаток управління корзинами, оплати та друку чеків.',
      'Веб-панель адміністратора для керування пристроями, запасами та продуктами.',
      'Проектування та збірка пристроїв на базі планшетів Android, власних плат PCB, читачів карток, ваг та термічних принтерів.',
    ]
  },
  {
    id: "english_words",
    type: ["Education", "UI/UX", "Android", "iOS"],
    title: "English Words",
    subtitle: "Створіть ваш власний словник",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_Cover_i7qm0l.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_screen_209_sg0em0.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556428/english_words_Screens_ufjppz.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709556429/english_words_Phones_mockup_-_English_Words_jrpkli.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177232/tetalab%20site/english_z6x1rf.png",
    previewDescription: "Додаток сприяє вивченню мови, віддаючи перевагу топ-1000 словам і забезпечуючи легке управління словниковим запасом.",
    description: "Чи ви знаєте, що вивчивши 1000 найчастіше вживаних слів, ви зможете зрозуміти 70% фраз?\n" +
      " \n" +
      "Під час вивчення нової мови завжди виникає потреба записувати невідомі слова. Деякі люди використовують для цього нотатки, тоді як інші віддають перевагу аркушу паперу. Наш додаток допоможе вам вирішити цю проблему і створити власний словник.",
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
          PLATFORMS.IOS,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.KOTLIN,
          TECHNOLOGIES.SWIFT,
          TECHNOLOGIES.TEXT_TO_SPEECH,
          TECHNOLOGIES.IN_APP_PURCHASE,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.SKETCH,
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Нашою основною метою було допомогти людям, які вивчають мову, запам\'ятовувати найчастіше вживані слова, сприяючи розумінню 70% загальних фраз.',
      'Ми прагнули оптимізувати запис і організацію нових слів, надаючи користувачам зручну платформу для створення та управління їх словником.',
      'Нашим завданням було створити інтуїтивний додаток для людей, що вивчають англійську мову. Додаток має спонукати до запам\'ятовування, розширювати словниковий запас та забезпечувати правильну вимову.',
      'Для збереження мотивації користувачів додаток потребував функцій для відстеження прогресу, що дозволяє користувачам моніторити їх досягнення, переглядати слова та оцінювати правильність вимови.',
    ],
    resumes: [
      'Додаток "English Words", спрямований на вивчення топ-1000 слів, які допоможуть користувачам розуміти 70% загальних фраз для розуміння мови.',
      'Додаток спрощує організацію слів, замінюючи традиційні методи вивчення інтуїтивним інтерфейсом для створення та управління особистими словниками.',
      'Людям, що вивчають англійську мову, "English Words" допомагає запам\'ятовувати слова, розширяти словниковий запас та ставити правильну вимову з урахуванням різних рівнів володіння мовою.',
      'Крім розширення словникового запасу, додаток сприяє відстеженню прогресу, має вправи з вимови та повторення слів для всебічного вивчення мови.',
      '"English Words" розроблений для щоденного використання та безперешкодно вписується в ритм користувачів, забезпечуючи постійний доступ до особистих словників.',
      'Дозволяючи користувачам створювати словники, "English Words" пропонує персоналізований шлях вивчення мови на власному темпі.',
    ],
    result: '"English Words" трансформує процес вивчення мови, зосереджуючись на топ-1000 слів, сприяючи розумінню 70% загальних фраз. Додаток спрощує управління словником завдяки зручному інтерфейсу, призначеному для людей, що вивчають англійську мову.  Завдяки цьому облегшується запам\'ятовування, тренування вимови та відстеження прогресу. Розроблений для щоденного використання, додаток безперешкодно вписується в ритм життя користувачів, надаючи постійний доступ до особистих словників і сприяючи постійному покращенню рівня володіння англійською мовою.'
  },
  {
    id: "mind_map",
    type: ["Utils", "UI/UX", "Android"],
    title: "Mind Map",
    subtitle: "Все, що вам потрібно - в одному місці",
    thumbnail: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_Cover_b3turk.jpg",
    logo_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_logoimg_nprsmj.png",
    resumeImg: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/mind_map_resume_mdt7if.png",
    bg_img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709557176/Phones_mockup_-_Mind_Map_jk3msc.png",
    img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718177307/tetalab%20site/mind_qrllga.png",
    previewDescription: '"Mind Map" - додаток, що оптимізує організаційні потреби, забезпечуючи ефективний та зручний досвід управління думками та цілями.',
    description: "Люди полюбляють мріяти і думати, але іноді не мають чогось, що буде зберігати їх думки. Одним з найкращих варіантів є використання смартфону, який завжди з користувачем. \n" +
      " \n" +
      "Ми розробили мобільний додаток для збереження списків цілей і тем, відстеження прогресу, планування завдань та інших функцій.\n" +
      " \n" +
      "Додаток надає платформу для складання списків цілей, відстеження пріоритетів та моніторингу результатів.",
    links: [
      {
        logo: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1671535425/Projects/FFF_1_qwhdva.svg",
        link: "https://www.behance.net/gallery/95191207/MindMap-Task-organizer"
      }
    ],
    specifications: [
      {
        platform: [
          PLATFORMS.ANDROID,
        ],
      },
      {
        technologies: [
          TECHNOLOGIES.JAVA,
          TECHNOLOGIES.ANDROID,
          TECHNOLOGIES.ROOM,
        ],
      },
      {
        tools: [
          TOOLS.FIGMA,
          TOOLS.ILLUSTRATOR,
        ],
      },
      {
        term: [
          {
            title: "3 weeks",
            img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1709113468/tetalab%20site/term3w_opwuae.png"
          }
        ]
      }
    ],
    team: [
      TEAM.UIUX_DESIGNER,
      TEAM.MOBILE_DEVELOPER,
      TEAM.QA
    ],
    challenges: [
      'Створення мобільного додатку, який забезпечить користувачам зручну та доступну платформу для зберігання й організації їхніх думок, цілей та розкладів.',
      'Забезпечення швидкого доступу користувачів до їхньої інформації у будь-який час та в будь-якому місці. Створення системи, яка ефективно обробляє великі обсяги даних.',
      'Розробка комплексного інструмента для управління та моніторингу особистих і професійних цілей.',
      'Інтеграція функцій для відстеження прогресу, планування та організації необхідної інформації. Це надає користувачам комплексне рішення для управління своїми думками та цілями в одному централізованому місці.',
    ],
    resumes: [
      'Ми розробили мобільний додаток, який служить централізованою платформою для користувачів, де вони можуть зберігати, організовувати та відстежувати свої цілі, думки та прогрес. Користувачі можуть ефективно керувати мріями та амбіціями в одному місці.',
      'Додаток надає динамічний список цілей, що дозволяє користувачам відстежувати пріоритети та оцінювати результати. Ця функція покращує організаційні можливості користувачів і допомагає їм залишатися сфокусованими на своїх цілях.',
      'Додаток "Mind Map" гарантує, що користувачі мають миттєвий доступ до завдань, думок, розкладів та важливої інформації в будь-який час і в будь-якому місці.',
      'Ми створили зручний для користувача інтерфейс, що дозволяє легко орієнтуватися у функціях додатку "Mind Map". Дизайн забезпечує легкий досвід користувачів у керуванні своїми думками та цілями.',
      'Ми розробили додаток для ефективної обробки та організації даних. Користувачі можуть використовувати додаток "Mind Map" для швидкого доступу до важливої інформації.',
      'Результатом став універсальний додаток "Mind Map", що виступає як комплексне рішення для організаційних потреб користувачів. Він об\'єднує завдання, думки, цілі, розклади та важливу інформацію в одному місці.',
    ],
    result: 'Додаток "Mind Map" пропонує комплексну платформу для зручної організації думок та цілей користувачів. Він дозволяє відстежувати прогрес, планувати, та управляти різноманітними цілями та темами. У сучасному світі, насиченому інформацією, додаток гарантує миттєвий доступ до завдань, думок, розкладів та необхідної інформації у будь-який час і в будь-якому місці. Він оптимізує організаційні потреби, надаючи ефективний та зручний досвід управління думками та цілями.'
  },
  // { //TODO temporary commented Healthcare. Add term and team info
  //   id: "healthcare_platform",
  //   type: ["UI/UX", "Android", "FrontEnd", "Backend", "Healthcare", "Flutter"],
  //   isDark: true,
  //   isCaseStudy: true,
  //   title: "Healthcare Platform",
  //   subtitle: "Оптимізація отримання рецептів та планування прийомів",
  //   thumbnail: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971903/pexels-soumil-kumar-735911_2_eksyrx.jpg",
  //   logo_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_1000003094_i5vnkg.png",
  //   resumeImg: "https://res.cloudinary.com/dvjdkgzbe/image/upload/v1718360693/Main/Group_1000003055_seirtf.svg",
  //   bg_img: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_705_comhlo.jpg",
  //   img: "https://res.cloudinary.com/doa9yehoc/image/upload/v1718180527/tetalab%20site/healthcare_ohafcq.png",
  //   previewDescription: "Healthcare Platform оптимізує управління рецептами та планування прийому, забезпечуючи координацію між лікарями та пацієнтами.",
  //   description: "Основною метою було створення інноваційного додатка, який відповідає різноманітним потребам лікарів та пацієнтів, з акцентом на інтуїтивний інтерфейс та зручність використання.\n" +
  //     " \n" +
  //     "Було необхідно модернізувати існуючу систему для оптимізації адміністративних завдань та робочих процесів, забезпечення надійних каналів зв'язку та впровадження заходів захисту даних для забезпечення конфіденційності інформації.",
  //   clientDescription: "Минулого року наша компанія отримала запит від клієнта на розробку мобільного додатка та панелі адміністратора для клініки в Німеччині. Мета - створити додаток, який безперешкодно відповідає потребам як лікарів, так і пацієнтів, надаючи перевагу простоті використання та зручності.",
  //   clientDescriptionItems: [
  //     'Ефективні адміністративні рішення, що економлять час.',
  //     'Оптимізовані організаційні робочі процеси для лікарів.',
  //     'Надійний зв\'язок та взаємодія з пацієнтами.',
  //     'Детальний контроль за історією хвороби пацієнта.',
  //     'Повноцінний захист даних.',
  //   ],
  //   clientDescriptionImg: "https://res.cloudinary.com/dc4kiugor/image/upload/v1716971330/Group_1000003095_bspopj.png",
  //   specifications: [
  //     {
  //       platform: [
  //         PLATFORMS.ANDROID,
  //         PLATFORMS.IOS,
  //       ],
  //     },
  //     {
  //       technologies: [
  //         TECHNOLOGIES.KOTLIN,
  //         TECHNOLOGIES.ANDROID,
  //         TECHNOLOGIES.NODE_MCU,
  //         TECHNOLOGIES.REACT,
  //       ],
  //     },
  //     {
  //       tools: [
  //         TOOLS.FIGMA,
  //         TOOLS.PHOTOSHOP,
  //         TOOLS.ILLUSTRATOR,
  //       ],
  //     },
  //   ],
  //   challenges: [
  //     'Оптимізація адміністративних завдань для виділення більшої кількості часу на важливі питання.',
  //     'Створення простого та зрозумілого дизайну для забезпечення зручної навігації для всіх вікових груп.',
  //     'Забезпечення надійного завдяки чітким, зашифрованим та безпечним взаємодіям з пацієнтами.',
  //     'Забезпечення безпечної обробки та шифрування даних пацієнтів.',
  //   ],
  //   resumeDescription: "Ми спростили адміністративні процеси завдяки панелі адміністратора, що дозволило заощадити час медичному персоналу. Мобільний додаток надав безпечний зв'язок між лікарями та пацієнтами, усуваючи потребу в постійних дзвінках та електронних листах.",
  //   resumes: [
  //     'Розробили простий та інтуїтивно зрозумілий інтерфейс користувача для забезпечення легкої навігації для користувачів усіх вікових груп.',
  //     'Встановили систему безпеки із надійними методами шифрування та контрольованим доступом для захисту конфіденційних даних.',
  //     'Створили можливість оновлення даних пацієнтів та графіків прийому в реальному часі для більшої точності та ефективності.',
  //   ],
  //   result: 'Healthcare Platform - це система, призначена для підвищення ефективності адміністративних завдань, покращення комунікації з пацієнтами, спрощення управління пацієнтами та забезпечення захисту даних.',
  //   resultItems: [
  //     'Спрощення адміністративних завдань для лікарів та інтернів.',
  //     'Покращення прямої комунікація між лікарями та пацієнтами.',
  //     'Забезпечення безпечної обробки та шифрування даних пацієнтів.',
  //     'Інтеграція з календарями для легкого планування.',
  //   ],
  //   systemIncludes: [
  //     'Мобільні додатки, розроблені як для платформи Android, так і для iOS.',
  //     'Інтуїтивну панель адміністратора для оптимізації адміністративних завдань.',
  //     'Легкий дизайн для забезпечення зручної навігації для користувачів усіх вікових груп.',
  //     'Механізми безпеки даних найвищого рівня, включаючи шифрування, для забезпечення захисту даних.',
  //   ]
  // },
];

export { Projects_en, Projects_ua };
