import { collection, doc, getDocs, getDoc, setDoc, query, where, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { paths } from "../Constants";
import { generateUniqueId } from "./helpers";

const getArticles = async (isAdmin) => {
  const articlesCollectionRef = collection(db, "articles");
  
  const qEN = isAdmin
    ? query(articlesCollectionRef, where("lang", "==", "en"), where("deletedAt", "==", null))
    : query(
      articlesCollectionRef,
      where("lang", "==", "en"),
      where("isPublic", "==", true),
      where("deletedAt", "==", null)
    );
  
  const qUA = isAdmin
    ? query(articlesCollectionRef, where("lang", "==", "ua"), where("deletedAt", "==", null))
    : query(
      articlesCollectionRef,
      where("lang", "==", "ua"),
      where("isPublic", "==", true),
      where("deletedAt", "==", null)
    );
  
  const articlesEN = await getDocs(qEN);
  const articlesUA = await getDocs(qUA);
  
  return {
    articlesEN: articlesEN.docs.map(doc => ({ ...doc.data(), id: doc.id })),
    articlesUA: articlesUA.docs.map(doc => ({ ...doc.data(), id: doc.id })),
  };
};

const postArticle = async (article) => {
  const newArticleRef = doc(collection(db, "articles"));
  await setDoc(newArticleRef, article);
  
  const createdArticleSnap = await getDoc(newArticleRef);
  
  return createdArticleSnap.data();
};

const uploadImage = async (file) => {
  const imageRef = ref(storage, `images/${generateUniqueId()}`);
  await uploadBytes(imageRef, file);
  return getDownloadURL(imageRef);
};

const loadData = async (setLoadedArticles, isAdmin) => {
  try {
    setLoadedArticles(prevState => ({
      ...prevState,
      isLoading: true,
    }));
    const articles = await getArticles(isAdmin);
    
    setLoadedArticles(prevState => ({
      ...prevState,
      articlesEN: articles.articlesEN,
      articlesUA: articles.articlesUA,
    }));
  } catch {
    console.log('Data isn`t loaded!');
  } finally {
    setLoadedArticles(prevState => ({
      ...prevState,
      isLoading: false,
    }));
  }
};

const deleteArticle = async (articleId, navigate, lang) => {
  const docRef = doc(db, "articles", articleId);
  
  const updatedData = {
    deletedAt: new Date,
    isPublic: false
  };
  
  await updateDoc(docRef, updatedData);
  
  navigate(`/${lang}${paths.blog}`);
};

const updateArticle = async (articleId, article) => {
  const docRef = doc(db, "articles", articleId);
  
  const data = {
    title: article.title,
    date: article.date.toString(),
    readTime: article.readTime,
    article: article.article,
    isPublic: article.isPublic,
    img: article.img,
    name: article.name,
    lang: article.lang,
    deletedAt: article.deletedAt
  }
  
  await updateDoc(docRef, data);
};

const getArticleById = async (articleId, lang, setFormValues) => {
  const articlesCollectionRef = collection(db, "articles");
  
  const q = query(articlesCollectionRef, where("lang", "==", lang), where("name", "==", articleId));
  
  const article = await getDocs(q);
  
  const res = article.docs.map(doc => ({ ...doc.data(), id: doc.id }));
  
  return setFormValues({
    title: res[0]?.title ?? '',
    date: new Date(res[0]?.date) ?? '',
    readTime: res[0]?.readTime ?? '',
    article: res[0]?.article ?? '',
    isPublic: res[0]?.isPublic ?? '',
    img: res[0]?.img ?? '',
    name: res[0]?.name ?? '',
    lang: res[0]?.lang ?? '',
    deletedAt: res[0]?.deletedAt ?? null,
    id: res[0]?.id
  });
};

export {
  getArticles,
  postArticle,
  deleteArticle,
  updateArticle,
  getArticleById,
  uploadImage,
  loadData,
}